import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
    Account,
    GetLoggedInUserAccountsResponse,
    MemberInvitationResponse,
    RescindInvitationInput,
    SendMemberInvitationInput,
    Member,
    UpdateMemberInput,
    UpdateAccountInput,
    MemberInvitationState,
    AccountMemberState,
    AcceptAccountInvitationProps,
} from "./types"
import { transformErrorResponse } from "utils/Utils"
import { ENV } from "config"
import { baseQueryWithReauth } from "features/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/accounts`})

export const accountsAPI = createApi({
    reducerPath: 'accountsAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['Accounts'],
    endpoints: (builder) => ({
        getAccount: builder.query<Account, string>({
            query: (id) => ({url: `/${id}`}),
            providesTags: (result, error, id) => [{type: 'Accounts', id}],
            transformResponse: (response: Account) => ({
                ...response,
                member_invitations: response.member_invitations.filter(({invitation_state}) =>
                    [MemberInvitationState.SENT, MemberInvitationState.RESENT].includes(invitation_state)),
                members: response.members.filter(({account_state}) => account_state === AccountMemberState.ACTIVE),
            }),
            transformErrorResponse,
        }),
        getAccountsCurrentUserHasAccessTo: builder.query<GetLoggedInUserAccountsResponse, void>({
            query: () => ({}),
            providesTags: [{type: 'Accounts', id: 'LIST'}],
            transformResponse: (response: GetLoggedInUserAccountsResponse) => {
                // Sort accounts by name we don't want inconsistences since the server returns by the order of last modified
                response.accounts.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    } else if (a.name > b.name) {
                        return 1
                    } else {
                        return 0
                    }
                })
                return response
            },
            transformErrorResponse,
        }),
        sendInvite: builder.mutation<MemberInvitationResponse, SendMemberInvitationInput>({
            query: ({account_id, ...body}) => ({
                url: `/${account_id}/invitations`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (data) => [{type: 'Accounts', id: data?.account_id}],
            transformErrorResponse,
        }),
        rescindInvite: builder.mutation<MemberInvitationResponse, RescindInvitationInput>({
            query: (body) => ({
                url: `/invitations/rescindments`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (data) => [{type: 'Accounts', id: data?.account_id}],
            transformErrorResponse,
        }),
        updateMember: builder.mutation<Member, UpdateMemberInput>({
            query: ({accountId, userId, ...body}) => ({
                url: `/${accountId}/members/${userId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (data, error, {accountId: id}) => [{type: 'Accounts', id}],
            transformErrorResponse,
        }),
        updateAccount: builder.mutation<Account, UpdateAccountInput>({
            query: (body) => ({
                url: `/${body.accountId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (data, error, {accountId: id}) => [{type: 'Accounts', id}, {type: 'Accounts', id: 'LIST'}],
            transformErrorResponse,
        }),
        acceptAccountInvitation: builder.mutation<void, AcceptAccountInvitationProps>({
            query: ({accountId, ...body}) => ({
                url: `/${accountId}/invitations/response`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{type: 'Accounts', id: 'LIST'}],
            transformErrorResponse,
        }),
    }),
})

export const {
    useGetAccountQuery,
    useSendInviteMutation,
    useGetAccountsCurrentUserHasAccessToQuery,
    useRescindInviteMutation,
    useUpdateMemberMutation,
    useAcceptAccountInvitationMutation,
} = accountsAPI
