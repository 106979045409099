import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils/Utils"
import { ENV } from "config"
import { GetProfileResponse } from "./types"
import { baseQueryWithReauth } from "features/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/users/`})

export const profileAPI = createApi({
    reducerPath: 'profileAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    endpoints: (builder) => ({
        getProfile: builder.query<GetProfileResponse, void>({
            query: () => ({
                url: '/profile',
                method: 'GET',
            }),
            transformErrorResponse,
        }),
    }),
})

export const {useGetProfileQuery} = profileAPI
