import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AuthResponse, AuthenticationState } from "./types"
import { RootState } from "redux/store"
import { revokeTokens, getTokens, setTokens } from './utils/storage'
import { ENV } from "config"
import axios from "axios"
import { authenticationAPI } from "./authenticationAPI"

const initialState: AuthenticationState = {
    currentUser: null,
    loading: true,
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: () => {
            revokeTokens()
            return {
                currentUser: null,
                loading: false,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(receiveTokens.fulfilled, (_, action) => ({
                currentUser: action.payload,
                loading: false,
            }))
            .addCase(checkRefreshTokenValidity.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(checkRefreshTokenValidity.fulfilled, (_, action) => ({
                currentUser: action.payload,
                loading: false,
            }))
            .addCase(checkRefreshTokenValidity.rejected, (state) => ({
                ...state,
                leading: false,
            }))
            .addMatcher(authenticationAPI.endpoints.login.matchPending, (state) => ({
                ...state,
                loading: true,
            }))
            .addMatcher(authenticationAPI.endpoints.login.matchRejected, (state) => ({
                ...state,
                loading: false,
            }))
            .addMatcher(authenticationAPI.endpoints.login.matchFulfilled, (_, action) => ({
                currentUser: action.payload,
                loading: false,
            }))
            .addMatcher(authenticationAPI.endpoints.signUp.matchFulfilled, (_, action) => ({
                currentUser: action.payload,
                loading: false,
            }))
    },
})

export const receiveTokens = createAsyncThunk(
    'authentication/receiveTokens',
    async (authResponse: AuthResponse) => await setTokens(authResponse)
)

export const checkRefreshTokenValidity = createAsyncThunk(
    'authentication/checkRefreshTokenValidity',
    async (_, {dispatch}) => {
        const {refreshToken} = await getTokens()
        if (!refreshToken) {
            dispatch(logout())
            throw new Error('No refresh token available')
        }

        try {
            const response = await axios.post(`${ENV.ALEXIARES_HOST_URL}/users/tokenrefresh`, {
                refresh_token: refreshToken,
                grant_type: 'refresh_token',
            })
            return await setTokens(response.data)
        } catch (error: any) {
            dispatch(logout())
            throw new Error(error.message);
        }
    }
)

export const {logout} = authenticationSlice.actions
export const currentUser = (state: RootState) => state.authentication.currentUser
export const authenticationState = (state: RootState) => state.authentication
export default authenticationSlice.reducer
