import "./App.css"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import { persistor, store } from "./redux/store"
import { Provider } from "react-redux"
import Router from "./router/Router"
import { Suspense } from "react"
import { Toaster } from "react-hot-toast"
import { PersistGate } from "redux-persist/integration/react"
import "preline"
import ErrorScreen from "components/ErrorScreen/ErrorScreen"
import { ErrorBoundary } from "react-error-boundary"
import Mixpanel from "components/Mixpanel/Mixpanel"
import ModalProvider from "components/ModalProvider/ModalProvider"
import LoadAppData from "components/LoadAppData/LoadAppData"
import RedirectProvider from "components/RedirectProvider/RedirectProvider"

function App() {
    return (
        <div className="App">
            <ErrorBoundary FallbackComponent={ErrorScreen}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Suspense fallback={<>Loading ...</>}>
                            <RedirectProvider>
                                <Router />
                            </RedirectProvider>
                        </Suspense>
                        <Toaster
                            position="bottom-right"
                            reverseOrder={false}
                            gutter={8}
                            containerClassName="z-[100000]"
                            containerStyle={{}}
                            toastOptions={{
                                // Define default options
                                className: "",
                                duration: 3000,
                                style: {
                                    background: "#005EFF",
                                    color: "white",
                                },
                            }}
                        />
                        <Mixpanel />
                        <LoadAppData />
                        <ModalProvider />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </div>
    )
}

export const createApp = () => {
    return <App />
}
