import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { getCurrentTimePlusNMinutes } from "utils/Utils"

type CompanyTimers = Record<string, {
    dashboards: number
}>

const sidebar = createSlice({
    name: "sidebar",
    initialState: {
        isExpanded: false,
        featureTimers: {} as CompanyTimers,
    },
    reducers: {
        setIsExpanded: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isExpanded = action.payload
        },
        initFeatureTimers: (
            state,
            action: PayloadAction<string>,
        ) => {
            state.featureTimers[action.payload] = {
                dashboards: getCurrentTimePlusNMinutes(15),
            }
        },
    },
})

export const {setIsExpanded, initFeatureTimers} = sidebar.actions
export const sidebarState = (state: RootState) => state.sidebar
export default sidebar.reducer
