import Envelope from "assets/svg/account-settings/envelope.svg"

interface Props {
    isInvalid: boolean
    onChange: (value: string) => void
    onBlur: () => void
    value: string
}

export default function EmailInput({
    isInvalid,
    onChange,
    onBlur,
    value,
}: Props) {

    const handleChange = (e: any) => {
        onChange(e.target.value as string)
    }

    return (
        <div className="flex flex-col gap-[10px]">
            <div className="h-[62px] border-[1px] border-slate-200 rounded-md relative shadow-sm">
                <img className="absolute left-[20px] top-[50%] transform translate-y-[-50%]" src={Envelope} />
                <input className="outline-bone border-none w-full h-full !pl-[46px] !pr-[20px] rounded-md placeholder-slate-500"
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    placeholder="Enter user's email address"
                />
            </div>
            {isInvalid && (
                <div className="pl-[20px] text-left text-sm text-red-600">Invalid Email</div>
            )}
        </div>
    )
}