import { AuthResponse, User } from "../types"
import jwt from "jwt-decode"
import { decryptJWT, encryptJWT } from "./token"

const _v1_refresh_token_key = 'refreshToken';
const _v2_refresh_token_key = 'v2_refreshToken';
const _access_token_key = 'accessToken';

export const setTokens = async ({access_token, refresh_token}: AuthResponse): Promise<User> => {
    const encryptedRefreshToken = await encryptJWT(refresh_token)
    localStorage.setItem(_v2_refresh_token_key, encryptedRefreshToken)
    localStorage.setItem(_access_token_key, access_token)
    return jwt(access_token) as User
}

export const revokeTokens = () => {
    localStorage.removeItem(_access_token_key)
    localStorage.removeItem(_v1_refresh_token_key)
    localStorage.removeItem(_v2_refresh_token_key)
}

export const getTokens = async () => {
    const accessToken = localStorage.getItem(_access_token_key);
    const v1_refresh_token = localStorage.getItem(_v1_refresh_token_key);
    if (v1_refresh_token) {
        // This migrates the user's refresh token from the old format to the
        // new format. We can remove this code after all refresh tokens that
        // would have been stored in the old format have either been migrated
        // (which is difficult to tell) or after they would have expired.
        const encryptedRefreshToken = await encryptJWT(v1_refresh_token);
        localStorage.setItem(_v2_refresh_token_key, encryptedRefreshToken);
        localStorage.removeItem(_v1_refresh_token_key);
        return {accessToken, refreshToken: v1_refresh_token};
    }
    const encryptedRefreshToken = localStorage.getItem(_v2_refresh_token_key);
    const refreshToken = await decryptJWT(encryptedRefreshToken)
    return {accessToken, refreshToken}
}