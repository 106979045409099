import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface QuickBooksState {
  connectedCompanies: { [companyId: string]: boolean };
}

const initialState: QuickBooksState = {
  connectedCompanies: {},
};

const quickbooksSlice = createSlice({
  name: 'quickbooks',
  initialState,
  reducers: {
    setQuickBooksConnected(state, action: PayloadAction<{ companyId: string; connected: boolean }>) {
      const { companyId, connected } = action.payload;
      state.connectedCompanies[companyId] = connected;
    },
  },
});

export const { setQuickBooksConnected } = quickbooksSlice.actions;
export default quickbooksSlice.reducer;