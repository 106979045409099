export interface Company {
    id: string
    event_type: number
    state: CompanyState
    account_id: string
    name: string
    legal_entity_name: string
    contributors: Contributor[]
    industries: Industry[]
    tools: CompanyToolId[]
    website: string
    presentation_config: PresentationConfig
    contributor_invitations: ContributorInvitation[]
    pending_connection_names: string[]
    users_with_access: UsersWithAccess[]
}

export interface UsersWithAccess {
    id: string
    first_name: string
    last_name: string
    email: string
    confirmed_at: string
}

export interface Contributor {
    id: string
    first_name: string
    last_name: string
    email: string
    confirmed_at: string | null
}

export interface Industry {
    id: number
    name: string
    group_id: number
}

export interface GetIndustriesResponse {
    industries: Industry[]
}

interface PresentationConfig {
    avatar_url?: string | null
    avatar_bg_color: number
}
export const enum ContributorInvitationState {
    /**
     * The invitation _HAS NOT_ been sent yet. This is the state of the invitation
     * prior to the company being activated.
     */
    Unsent = -1,
    /**
     * The invitation was sent and has not yet been responded to.
     */
    Sent = 0,
    /**
     * The invitation was accepted.
     */
    Accepted = 1,
    /**
     * The invitation was declined.
     */
    Declined = 2,
    /**
     * The invitation was rescinded.
     */
    Rescinded = 3,
    /**
     * The invitation was resent.
     */
    Resent = 4,
    /**
     * The invitation has expired. This is a synthetic state, and is computed
     * based upon the date.
     */
    Expired = 5,
}
export interface ContributorInvitation {
    id: string
    company_id: string
    invitee_email: string
    state: ContributorInvitationState
    expires_at: string
    sent_date: string
    connections_to_authenticate: string[]
}

export interface CompanyTool {
    id: CompanyToolId
    name: string
    description: string
    is_enabled: boolean
    group_id: number
}

export enum IndustryGroup {
    AgricultureAndForestry = 1,
    ArtsEntertainmentAndRecreation = 2,
    BankingAndFinancialServices = 3,
    Construction = 4,
    Education = 5,
    EnergyAndUtilities = 6,
    HealthcareAndSocialAssistance = 7,
    InformationAndCommunications = 8,
    Manufacturing = 9,
    MiningQuarryingAnOilAndGas = 10,
    ProfessionalScientificAndTech = 11,
    RealEstateRentalAndLeasing = 12,
    RetailTrade = 13,
    TransportationAndWarehousing = 14,
    WholesaleTrade = 15,
    TechnologyAndSoftware = 16,
}

export const IndustryGroupToStringMap: { [key: number]: string } = {
    [IndustryGroup.AgricultureAndForestry]: "Agriculture & Forestry",
    [IndustryGroup.ArtsEntertainmentAndRecreation]:
        "Arts, Entertainment & Recreation",
    [IndustryGroup.BankingAndFinancialServices]: "Banking & Financial Services",
    [IndustryGroup.Construction]: "Construction",
    [IndustryGroup.Education]: "Education",
    [IndustryGroup.EnergyAndUtilities]: "Energy & Utilities",
    [IndustryGroup.HealthcareAndSocialAssistance]:
        "Healthcare & Social Assistance",
    [IndustryGroup.InformationAndCommunications]:
        "Information & Communications",
    [IndustryGroup.Manufacturing]: "Manufacturing",
    [IndustryGroup.MiningQuarryingAnOilAndGas]:
        "Mining, Quarrying, and Oil & Gas",
    [IndustryGroup.ProfessionalScientificAndTech]:
        "Professional Scientific & Tech",
    [IndustryGroup.RealEstateRentalAndLeasing]: "Real Estate, Rental & Leasing",
    [IndustryGroup.RetailTrade]: "Retail Trade",
    [IndustryGroup.TransportationAndWarehousing]:
        "Transportation & Warehousing",
    [IndustryGroup.WholesaleTrade]: "Wholesale Trade",
    [IndustryGroup.TechnologyAndSoftware]: "Technology & Software",
}

export enum CompanyToolGroup {
    DataSources = 1,
    Tools = 2,
}

export enum CompanyToolId {
    // Data Sources
    Financials = CompanyToolGroup.DataSources + (1 << 8),
    Marketing = CompanyToolGroup.DataSources + (2 << 8),
    Customers = CompanyToolGroup.DataSources + (3 << 8),
    // Tools
    Alerts = CompanyToolGroup.Tools + (1 << 8),
    ChatAPK = CompanyToolGroup.Tools + (2 << 8),
}

export enum CompanyCreationStep {
    Overview = "overview",
    SelectTools = "select_tools",
    SelectFinancialDataSources = "select_financial_data_sources",
    SelectMarketingDataSources = "select_marketing_data_sources",
    InviteContributors = "invite_contributors",
    Review = "review",
}

export type UpdateCompanyResponse = {
    current_step: CompanyCreationStep
    company: Company
}

export type UpdateCompanyInput = {
    id: string
    commit_value?: boolean
    name?: string
    legal_entity_name?: string
    industries_to_add?: number[]
    industries_to_remove?: number[]
    tools_to_add?: number[]
    tools_to_remove?: number[]
    pending_connections_to_add?: string[]
    pending_connections_to_remove?: string[]
    website?: string
    presentation_config?: PresentationConfig
}

export type GetCompanyToolsResponse = {
    tools: CompanyTool[]
}

// GET: /Companies
export interface CompanyIndexItem {
    id: string
    event_type: CompanyEventTypeId
    state: CompanyState
    name: string
    industries: Industry[]
    presentation_config: PresentationConfig
}
export enum CompanyEventTypeId {
    Monitor = 1,
    Acquisition = 2,
    DebtFinancing = 3,
    PrepareForSale = 4,
    EquityInvestment = 5,
}

export enum CompanyState {
    Created = 0,
    Activated = 1,
    Canceled = 2,
    Archived = 3,
}

// Event types types

export interface EventType {
    id: number
    name: string
    description: string
    is_enabled: boolean
}

// Create company types
export interface CreateCompanyInput {
    account_id: string
    event_type: CompanyEventTypeId
}

export interface CreateCompanyResponse {
    current_step: CompanyCreationStep.Overview
    company: Company
}

// Remove invitation
export interface RemoveCompanyContributorInvitationInput {
    company_id: string
    invitation_id: string
}

// GET company contributor invitations
export interface GetCompanyContributorInvitationsResponse {
    companies: {
        account_id: string
        invitations: ContributorInvitation[]
        index_item: CompanyIndexItem
    }[]
}

// PATCH update company contributor invitation

export interface UpdateCompanyContributorInvitationInput {
    connections_to_add: string[]
    connections_to_remove: string[]
    company_id: string
    invitation_id: string
}
