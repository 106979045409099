import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils/Utils"
import { ENV } from "config"
import {
    Alert,
    AlertCommentActivity,
    GetAlertActivitiesResponse,
    KeyPerformanceIndicatorCategory,
    UpdateAlertInput,
} from "./types"
import { AlertsWithTrend } from "@apokto/feature-eulabeia-api"
import { createEulabeiaApi, EulabeiaAlertListV2GETRequestBuilder } from "@apokto/feature-eulabeia-client"
import { createAlexiaresApi } from "@apokto/feature-alexiares-client"
import { baseQueryWithReauth } from "features/authentication/utils/query"
import { receiveTokens } from "features/authentication/authenticationSlice"
import { getTokens } from "features/authentication/utils/storage"
import { sortAlertsById } from "./utils"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.EULABEIA_HOST_URL}/`})

export const alertsAPI = createApi({
    reducerPath: 'alertsAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['Alert', 'Alerts', 'Activity'],
    endpoints: (builder) => ({
        getAlertsV2: builder.query<AlertsWithTrend, {accountId: string, companyId?: string}>({
            queryFn: async ({accountId, companyId}, {dispatch}) => {
                const eulabeiaApi = createEulabeiaApi(ENV.EULABEIA_HOST_URL)
                const {accessToken, refreshToken} = await getTokens()
                try {
                    if (!accessToken) {
                        throw new Error('Invalid token')
                    }
                    const modifyRequest = (builder: EulabeiaAlertListV2GETRequestBuilder) => {
                        builder.addAccountId(accountId)
                        if (companyId) {
                            builder.addCompanyId(companyId)
                        }
                        builder.bearerToken(accessToken)
                    }
                    
                    let alerts_response = await eulabeiaApi.fetchAlertsV2(modifyRequest)

                    if (alerts_response.status === 401 && refreshToken) {
                        const response = await createAlexiaresApi(ENV.ALEXIARES_HOST_URL).refreshToken((builder) => {
                            builder.refreshToken(refreshToken)
                        })

                        const token_data = response.data;
                        if (token_data) {
                            await dispatch(receiveTokens(token_data))
                            alerts_response = await eulabeiaApi.fetchAlertsV2((builder) => {
                                builder.addAccountId(accountId)
                                if (companyId) {
                                    builder.addCompanyId(companyId)
                                }
                                builder.bearerToken(token_data.access_token)
                            })
                        }
                    }

                    if (alerts_response.data) {
                        return { data: alerts_response.data }
                    } else {
                        return {
                            error: transformErrorResponse(
                                {status: 'CUSTOM_ERROR', error: 'An error occurred while fetching'},
                                undefined,
                                null
                            ) as FetchBaseQueryError,
                        }
                    }
                } catch (error) {
                    return {
                        error: transformErrorResponse(
                            {status: 'CUSTOM_ERROR', error: 'An error occurred while building the query'},
                            undefined,
                            null
                        ) as FetchBaseQueryError,
                    }
                }
            },
            providesTags: [{type: 'Alerts', id: 'AlertsWithTrend'}],
        }),
        getAlerts: builder.query<Array<Alert>, string>({
            query: (companyId) => ({url: `?company_id=${companyId}`}),
            transformErrorResponse,
            transformResponse: sortAlertsById,
            providesTags: [{type: 'Alerts', id: 'LIST'}],
        }),
        getKPICategories: builder.query<KeyPerformanceIndicatorCategory[], string>({
            query: (companyId) => ({url: `/categories?company_id=${companyId}`}),
            transformErrorResponse,
        }),
        createAlert: builder.mutation<{id: string}, string>({
            query: (companyId) => ({
                url: `?company_id=${companyId}`,
                method: 'POST',
            }),
            transformErrorResponse,
            invalidatesTags: () => [
                {type: 'Alerts', id: 'LIST'},
                {type: 'Alerts', id: 'AlertsWithTrend'},
            ],
        }),
        updateAlert: builder.mutation<Alert, {alertId: string} & UpdateAlertInput>({
            query: ({alertId, ...body}) => ({
                url: `/${alertId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (data, error, {alertId: id}) => [
                {type: 'Alert', id},
                {type: 'Alerts', id: 'LIST'},
                {type: 'Alerts', id: 'AlertsWithTrend'},
            ],
            transformErrorResponse,
        }),
        deleteAlert: builder.mutation<void, string>({
            query: (alertId) => ({
                url: `/${alertId}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [
                {type: 'Alerts', id: 'LIST'},
                {type: 'Alerts', id: 'AlertsWithTrend'}
            ],
            transformErrorResponse,
        }),
        getAlert: builder.query<Alert, string>({
            query: (alertId) => ({url: `/${alertId}`}),
            transformErrorResponse,
            providesTags: (data, error, id) => [{type: 'Alert', id}],
        }),
        getAlertActivities: builder.query<GetAlertActivitiesResponse, string>({
            query: (alertId) => ({url: `/${alertId}/activity`}),
            transformErrorResponse,
            providesTags: (result, error, id) => [{type: 'Activity', id}],
        }),
        postComment: builder.mutation<AlertCommentActivity, {content: string; alertId: string}>({
            query: ({alertId, ...body}) => ({
                url: `/${alertId}/comments`,
                method: 'POST',
                body,
            }),
            transformErrorResponse,
            invalidatesTags: (data, error, {alertId: id}) => [{type: 'Activity', id}],
        }),
        acknowledgeAlertTriggerActivity: builder.mutation<void,{alertId: string; activityId: string}>({
            query: ({alertId, activityId}) => ({
                url: `/${alertId}/triggers/${activityId}/acknowledgements`,
                method: 'PUT',
            }),
            transformErrorResponse,
            invalidatesTags: (data, error, {alertId: id}) => [{type: 'Activity', id}],
        }),
    }),
})

export const {
    useGetAlertsV2Query,
    useGetAlertsQuery,
    useLazyGetAlertsQuery,
    useGetKPICategoriesQuery,
    useCreateAlertMutation,
    useUpdateAlertMutation,
    useGetAlertActivitiesQuery,
    useLazyGetAlertActivitiesQuery,
    usePostCommentMutation,
    useGetAlertQuery,
    useAcknowledgeAlertTriggerActivityMutation,
    useDeleteAlertMutation,
} = alertsAPI
