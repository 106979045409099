import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit"
import type { PreloadedState } from "@reduxjs/toolkit"
import { accountsAPI } from "features/accounts/accountsAPI"
import accountsReducer from "features/accounts/accountsSlice"
import authenticationReducer, { logout } from "features/authentication/authenticationSlice"
import { billingsAPI } from "features/billing/billingsAPI"
import companiesReducer from "features/companies/companiesSlice"
import dashboardsReducer from "features/dashboards/dashboardsSlice"
import { companiesAPI } from "features/companies/companiesAPI"
import { financialsAPI } from "features/financials/financialsAPI"
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import zoeReducer from "features/zoe/zoeSlice"
import { zoeAPI } from "features/zoe/zoeAPI"
import { alertsAPI } from "features/alerts/alertsAPI"
import recentCompaniesReducer from "features/recentCompanies/recentCompaniesSlice"
import sidebarReducer from "features/sidebar/sidebarSlice"
import industriesReducer from "features/industries/industriesSlice"
import toolsReducer from "features/tools/toolsSlice"
import connectionsReducer from "features/connections/connectionsSlice"
import trackingMiddleware from "features/tracking/trackingMiddleware"
import trackingReducer from "features/tracking/trackingSlice"
import navReducer from "features/nav/navSlice"
import companySettingsReducer from "features/companySettings/companySettingsSlice"
import homeReducer from "features/home/homeSlice"
import { authenticationAPI } from "features/authentication/authenticationAPI"
import { profileAPI } from "features/profile/profileAPI"
import quickbooksReducer from "features/quickbooks/quickbooksSlice"

const persistConfig = {
    // Update the key to invalidate previous cache
    key: 'root_v4',
    storage,
    version: 3,
    whitelist: ['dashboards', 'recentCompanies', 'sidebar', 'tracking'],
}
const appReducer = combineReducers({
    /* app’s top-level reducers */
    [accountsAPI.reducerPath]: accountsAPI.reducer,
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [billingsAPI.reducerPath]: billingsAPI.reducer,
    [companiesAPI.reducerPath]: companiesAPI.reducer,
    [financialsAPI.reducerPath]: financialsAPI.reducer,
    [alertsAPI.reducerPath]: alertsAPI.reducer,
    [zoeAPI.reducerPath]: zoeAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,

    accounts: accountsReducer,
    authentication: authenticationReducer,
    companies: companiesReducer,
    dashboards: dashboardsReducer,
    zoe: zoeReducer,
    recentCompanies: recentCompaniesReducer,
    sidebar: sidebarReducer,
    industries: industriesReducer,
    tools: toolsReducer,
    connections: connectionsReducer,
    tracking: trackingReducer,
    nav: navReducer,
    companySettings: companySettingsReducer,
    home: homeReducer,
    quickbooks: quickbooksReducer,
})

const rootReducer = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction
) => {
    if (action.type === logout.type) {
        console.log("Logged out on top level")
        // Purges the redux store cache for dashboards slice
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const createStore = ({
    preloadedState,
}: {
    preloadedState?: PreloadedState<RootState>
}) =>
    configureStore({
        reducer: persistedReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(
                accountsAPI.middleware,
                authenticationAPI.middleware,
                billingsAPI.middleware,
                companiesAPI.middleware,
                financialsAPI.middleware,
                alertsAPI.middleware,
                zoeAPI.middleware,
                profileAPI.middleware,
                trackingMiddleware,
            ),
        devTools: true,
    })

export const store = createStore({})
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof appReducer>
export type AppDispatch = typeof store.dispatch
