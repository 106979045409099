import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils/Utils"
import { ENV } from "config/index"
import { getQueryParamsFromSession } from "features/tracking/utils"
import { Session, SessionName } from "features/tracking/types"
import { Conversation, ConversationSummary, MessageFeedbackBody } from "./types"
import { baseQueryWithReauth } from "features/authentication/utils/query"
import { getPlaceholderMessages } from "./utils"
import { setActiveConversationId, setPendingMessage } from "./zoeSlice"

const baseQuery = fetchBaseQuery({baseUrl: ENV.ZOE_HOST_URL})

export const zoeAPI = createApi({
    reducerPath: 'zoeAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['Conversations'],
    endpoints: (builder) => ({
        getConversations: builder.query<ConversationSummary[], string>({
            query: (companyId) => ({
                url: `/conversations/index?company_id=${companyId}`,
                method: 'GET',
            }),
            transformErrorResponse,
            providesTags: [{type: 'Conversations'}],
        }),
        getConversation: builder.query<Conversation, string>({
            query: (conversationId) => ({
                url: `/conversations/${conversationId}`,
                method: 'GET',
            }),
            transformErrorResponse,
        }),
        createConversation: builder.mutation<Conversation, {companyId: string, message: string}>({
            queryFn: async ({companyId, message}, api, extraOptions) => {
                const {tracking} = api.getState() as {tracking: Session}
                const sessionParams = getQueryParamsFromSession(tracking, SessionName.ZOE)
                const response = await baseQueryWithReauth(
                    baseQuery,
                    {
                        url: `/conversations?${sessionParams}`,
                        method: 'POST',
                        body: {company_ids: [companyId], label: 'New Chat'},
                    },
                    api,
                    extraOptions,
                )

                if (response.error) {
                    return {
                        error: transformErrorResponse(
                            response.error,
                            response.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }

                try {
                    const conversation: Conversation = await api.dispatch(
                        zoeAPI.endpoints.sendMessage.initiate({
                          conversationId: (response.data as Conversation).id,
                          message,
                        }) 
                    ).unwrap()

                    return {data: conversation}
                } catch(error) {
                    return {error: transformErrorResponse(error as FetchBaseQueryError) as FetchBaseQueryError}
                }
            },
            onQueryStarted: async ({companyId, message}, {dispatch, queryFulfilled}) => {
                dispatch(setPendingMessage(message))
                try {
                    const action = await queryFulfilled
                    dispatch(zoeAPI.util.updateQueryData('getConversation', action.data.id, () => action.data))
                    dispatch(setActiveConversationId({companyId, conversationId: action.data.id}))
                } catch (error) {
                    console.error(error)
                }
            },
            invalidatesTags: [{type: 'Conversations'}],
        }),
        deleteConversation: builder.mutation<Conversation, {companyId: string, conversationId: string}>({
            queryFn: async ({conversationId}, api, extraOptions) => {
                const {tracking} = api.getState() as {tracking: Session}
                const sessionParams = getQueryParamsFromSession(tracking, SessionName.ZOE)
                const response = await baseQueryWithReauth(
                    baseQuery,
                    {
                        url: `/conversations/${conversationId}?${sessionParams}`,
                        method: 'DELETE',
                    },
                    api,
                    extraOptions
                )

                if (response.error) {
                    return {
                        error: transformErrorResponse(
                            response.error,
                            response.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }

                return {data: response.data as Conversation}
            },
            onQueryStarted: async ({companyId, conversationId}, {dispatch, queryFulfilled}) => {
                try {
                    await queryFulfilled
                    dispatch(zoeAPI.util.updateQueryData('getConversations', companyId, (draft) => {
                        return draft.filter(conversation => conversation.id !== conversationId)
                    }))
                } catch (error) {
                    console.error(error)
                }
            },
        }),
        updateConversation: builder.mutation<Conversation, {companyId: string, conversationId: string, label?: string, is_pinned?: boolean}>({
            queryFn: async ({conversationId, ...body}, api, extraOptions) => {
                const {tracking} = api.getState() as {tracking: Session}
                const sessionParams = getQueryParamsFromSession(tracking, SessionName.ZOE)
                const response = await baseQueryWithReauth(
                    baseQuery,
                    {
                        url: `/conversations/${conversationId}?${sessionParams}`,
                        method: 'PATCH',
                        body,
                    },
                    api,
                    extraOptions,
                )

                if (response.error) {
                    return {
                        error: transformErrorResponse(
                            response.error,
                            response.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }

                return {data: response.data as Conversation}
            },
            onQueryStarted: async ({companyId, conversationId, ...patch}, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(zoeAPI.util.updateQueryData('getConversations', companyId, (conversations) => {
                    return conversations.map(conversation => {
                        return conversation.id === conversationId
                            ? {
                                ...conversation,
                                ...(patch.label !== undefined && {label: patch.label}),
                                ...(patch.is_pinned !== undefined && {is_pinned: patch.is_pinned}),
                            }
                            : conversation
                    })
                }))

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error(error)
                    patchResult.undo()
                }
            },
        }),
        sendMessage: builder.mutation<Conversation, {conversationId: string; message: string; extraOptions?: {signal?: AbortSignal}}>({
            queryFn: async ({ conversationId, message, extraOptions }, api) => {
                const {tracking} = api.getState() as {tracking: Session}
                const sessionParams = getQueryParamsFromSession(tracking, SessionName.ZOE)

                try {
                    const response = await baseQueryWithReauth(
                        baseQuery,
                        {
                            url: `/conversations/${conversationId}/messages?${sessionParams}`,
                            method: 'POST',
                            body: { message },
                            signal: extraOptions?.signal,
                        },
                        api,
                        {...extraOptions}
                    )
        
                    if (response.error) {
                        return {
                            error: transformErrorResponse(
                                response.error,
                                response.meta ?? ({} as any),
                                null
                            ) as FetchBaseQueryError,
                        }
                    }
        
                    return {data: response.data as Conversation}
                } catch (error) {
                    if (error instanceof Error && error.name === 'AbortError') {
                        console.log('Request aborted')
                        return {error: {status: 'FETCH_ERROR', error: 'Request aborted'}}
                    }
                    throw error
                }
            },
            onQueryStarted: async ({conversationId, message}, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(
                    zoeAPI.util.updateQueryData('getConversation', conversationId, (conversation) => ({
                        ...conversation,
                        messages: conversation.messages.concat(getPlaceholderMessages(message)),
                    }))
                )
                try {
                    const action = await queryFulfilled
                    dispatch(
                        zoeAPI.util.updateQueryData('getConversation', conversationId, () => action.data)
                    )
                } catch (error) {
                    console.error(error)
                    patchResult.undo()
                }
            },
            invalidatesTags: [{type: 'Conversations'}],
        }),
        submitFeedback: builder.mutation<Conversation, {conversationId: string, messageId: number} & MessageFeedbackBody>({
            queryFn: async ({conversationId, messageId, ...body}, api, extraOptions) => {
                const {tracking} = api.getState() as {tracking: Session}
                const sessionParams = getQueryParamsFromSession(tracking, SessionName.ZOE)
                const response = await baseQueryWithReauth(
                    baseQuery,
                    {
                        url: `/conversations/${conversationId}/messages/${messageId}/feedback?${sessionParams}`,
                        method: 'POST',
                        body,
                    },
                    api,
                    extraOptions,
                )

                if (response.error) {
                    return {
                        error: transformErrorResponse(
                            response.error,
                            response.meta ?? ({} as any),
                            null
                        ) as FetchBaseQueryError,
                    }
                }

                return {data: response.data as Conversation}
            },
        })
    }),
})

export const {
    useGetConversationsQuery,
    useLazyGetConversationQuery,
    useCreateConversationMutation,
    useDeleteConversationMutation,
    useUpdateConversationMutation,
    useSendMessageMutation,
    useSubmitFeedbackMutation,
} = zoeAPI
