import { logout } from "features/authentication/authenticationSlice";
import { PropsWithChildren, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";

export default function RedirectProvider({children}: PropsWithChildren) {
    const dispatch = useDispatch<AppDispatch>()
    const url = window.location.href
    const parsedUrl = new URL(url);
    const queryParams = new URLSearchParams(parsedUrl.search);
    const userHasVerfiedEmail = queryParams.get('confirmed')

    useEffect(() => {
        // triggered once user clicks on email verification for new profile
        // we need to log the current user out
        if (userHasVerfiedEmail === 'true') {
            dispatch(logout())
        }
    }, [userHasVerfiedEmail])

    return children
}
