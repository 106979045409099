import { Company } from "features/companies/types"
import archiveIcon from "assets/svg/company-settings/archive-modal.svg"
import { useState } from "react"
import Button from "components/common/Button"
import { useArchiveCompanyMutation } from "features/companies/companiesAPI"
import { APIError, ServerError } from "types/errors"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { accountsState } from "features/accounts/accountsSlice"
import { removeRecentCompany } from "features/recentCompanies/recentCompaniesSlice"

interface Props {
    onClose: () => void
    onComplete: () => void
    company: Partial<Company>
}

export default function ArchiveModal({onClose, onComplete, company}: Props) {
    const dispatch = useDispatch()
    const {currentWorkspaceAccountId} = useSelector(accountsState)
    const [archiveCompany, {isLoading: isArchiving}] = useArchiveCompanyMutation()
    const [hasArchived, setHasArchived] = useState(false)

    const handleArchive = () => {
        archiveCompany(company.id!).unwrap()
            .then(() => {
                setHasArchived(true)
                dispatch(removeRecentCompany({accountId: currentWorkspaceAccountId!, companyId: company.id!}))
            })
            .catch(({data}: APIError) => data.forEach(({msg}: ServerError) => toast.error(msg)))
    }

    return hasArchived ? (
        <div className="flex flex-col gap-5 items-center pb-10 px-10">
            <img className="h-16 w-16" src={archiveIcon} />
            <div className="flex flex-col items-center gap-[10px]">
                <div className="text-[#1F2937] text-2xl font-semibold tracking-[0.12px]">
                    {`${company.name} archived`}
                </div>
                <div className="text-base font-normal tracking-[0.08px] text-center text-[#6B7280]">
                    Your company, “{company.name}” has been archived successfully. Please e-mail&nbsp;
                    <a href="mailto:support@apokto.io" className="text-blue-500">support@apokto.io</a>
                    &nbsp;if you have any questions
                </div>
            </div>
            <Button
                small
                text="Got it"
                onClick={onComplete}
            />
        </div>
    ) : (
        <div className="flex flex-col gap-5 items-center pb-10 px-10">
            <img className="h-16 w-16" src={archiveIcon} />
            <div className="flex flex-col items-center gap-[10px]">
                <div className="text-[#1F2937] text-2xl font-semibold tracking-[0.12px]">
                    Confirm Archive
                </div>
                <div className="text-[#6B7280] text-[16px] font-normal leading-[24px] tracking-[0.08px]">
                    {`Are you sure you want to archive ${company.name}?`}
                </div>
            </div>
            <div className="flex gap-4">
                <Button
                    small
                    color="black"
                    plain
                    loading={isArchiving}
                    text={`Archive ${company.name}`}
                    onClick={handleArchive}
                />
                <Button
                    small
                    text="Cancel"
                    onClick={onClose}
                />
            </div>
        </div>
    )
}