// TODO for separation of concerns, this file should be split into multiple files

import {
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query"
import {
    APIError,
    AlexiariesError,
    AlexiariesInternalServerError,
} from "types/errors"
import * as Sentry from "@sentry/react"
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from "dayjs/plugin/relativeTime"
import jwt from "jwt-decode"
import { body as checkBody, ValidationChain} from "express-validator";
dayjs.extend(relativeTime);
dayjs.extend(duration);

export const validateEmail = (email: string): boolean => {
    const regexp = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    const isValid = email.toLowerCase().match(regexp) != null

    return isValid
}

export const isMobileBrowser = (): boolean => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    return regex.test(window.navigator.userAgent)
}

export const isTabletDevice = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)
}

// These are for dev as chrome emulator user agent doesn't work for tablet:

// export const isMobileBrowser = (): boolean => {
//     const isTouchScreen = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
//     const maxScreenWidth = 600; // Typical maximum screen width for mobile devices
//     const screenWidth = window.innerWidth;

//     return isTouchScreen && screenWidth <= maxScreenWidth;
// }

// export const isTabletDevice = (): boolean => {
//     const isTouchScreen = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
//     const minSize = Math.min(window.innerWidth, window.innerHeight);
//     const maxSize = Math.max(window.innerWidth, window.innerHeight);

//     return isTouchScreen && ((minSize >= 600 && maxSize >= 800) || (minSize >= 800 && maxSize >= 1000));
// }


export const SetToken = (
    jwtToken: string,
    jwtTokenRefresh: string,
    username: string
) => {
    localStorage.setItem("UserLogin_Token", jwtToken)
    localStorage.setItem("UserLogin_Token_Refresh", jwtTokenRefresh)
    localStorage.setItem("UserLogin_Name", username)
}
export const GetToken = (): boolean => {
    var token = localStorage.getItem("UserLogin_Token")
    return token != null && token.length > 0
}
export const RevokeToken = () => {
    localStorage.removeItem("UserLogin_Token")
    localStorage.removeItem("UserLogin_Name")
}

export const getInitialsFromName = (name: string): string => {
    if (name.split(" ").length >= 2) {
        const nameArr = name.split(" ")
        return (
            nameArr[0].charAt(0).toUpperCase() +
            nameArr[1].charAt(0).toUpperCase()
        )
    } else if (name.split(" ").length === 1) {
        return name.charAt(0).toUpperCase()
    }

    return "FH"
}

export const transformErrorResponse = (
    baseQueryReturnValue: FetchBaseQueryError,
    meta?: FetchBaseQueryMeta,
    arg?: any
): APIError => {
    try {
        if (
            baseQueryReturnValue.status === "FETCH_ERROR" ||
            baseQueryReturnValue.status === "TIMEOUT_ERROR"
        ) {
            return {
                status: baseQueryReturnValue.status,
                data: [
                    {
                        value: "",
                        msg: "Request failed, please check your network connection",
                    },
                ],
            }
        }
        if (baseQueryReturnValue.status === "PARSING_ERROR") {
            Sentry.captureException(
                new Error(
                    `Fetch error: Code ${baseQueryReturnValue.status} | URL - ${meta?.request.url}`
                )
            )
            return {
                status: baseQueryReturnValue.status,
                data: [
                    {
                        value: "",
                        msg: "Failed to parse response, please try again later",
                    },
                ],
            }
        }
        if (baseQueryReturnValue.status === 500) {
            Sentry.captureException(
                new Error(
                    `Fetch error: Code ${baseQueryReturnValue.status} | URL - ${meta?.request.url}`
                )
            )
            return {
                data: [
                    (baseQueryReturnValue.data as AlexiariesInternalServerError)
                        .errors,
                ],
                status: baseQueryReturnValue.status,
            }
        }
        if (baseQueryReturnValue.status === 'CUSTOM_ERROR') {
            Sentry.captureException(
                new Error(
                    `Fetch error: Code ${baseQueryReturnValue.status} | URL - ${meta?.request?.url}`
                )
            )
            return {
                data: [{value: '', msg: baseQueryReturnValue.error}],
                status: baseQueryReturnValue.status,
            }
        }
        return {
            data: (baseQueryReturnValue.data as AlexiariesError).errors,
            status: baseQueryReturnValue.status,
        }
    } catch {
        return {
            data: [{value: '', msg: 'Unknown Error'}],
            status: 'CUSTOM_ERROR',
        }
    }
}
/**
 * This flag is added to a fetch error when thrown, allowing it to be captured by the nearest error boundary.
 * We avoid logging it to Sentry, as logging fetch errors are already handled by the {@link transformErrorResponse} function.
 **/
export const createLoggedError = (message: any) => {
    return new Error("Logged: " + message)
}
export const formatPrice = (price: number): string => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    return formatter.format(price)
}

export const getAccountColor = (index: number) => {
    type AccountColorType = {
        bgColor: string
        textColor: string
    }
    const accountColor: AccountColorType[] = [
        {
            bgColor: "bg-gray-100",
            textColor: "text-slate-500",
        },
        {
            bgColor: "bg-yellow-100",
            textColor: "text-yellow-500",
        },
        {
            bgColor: "bg-green-100",
            textColor: "text-green-500",
        },
        {
            bgColor: "bg-orange-100",
            textColor: "text-orange-500",
        },
        {
            bgColor: "bg-blue-100",
            textColor: "text-blue-500",
        },
        {
            bgColor: "bg-purple-100",
            textColor: "text-purple-500",
        },
        {
            bgColor: "bg-lime-100",
            textColor: "text-lime-500",
        },
        {
            bgColor: "bg-pink-100",
            textColor: "text-pink-500",
        },
    ]

    if (index >= 0 && index < accountColor.length) {
        return accountColor[index]
    } else
        return {
            bgColor: "bg-gray-100",
            textColor: "text-slate-500",
        }
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

export function calculateArrayDifferences(
    prevArray: string[],
    currentArray: string[]
) {
    const itemsToAdd = currentArray.filter((item) => !prevArray.includes(item))

    const itemsToRemove = prevArray.filter(
        (item) => !currentArray.includes(item)
    )

    return { itemsToAdd, itemsToRemove }
}

export const capitalize = (word?: string): string | null => word ? word.charAt(0).toUpperCase() + word.slice(1) : null

export const ensureHttps = (url: string) => {
    if (url.startsWith('http://')) {
        return 'https://' + url.substring(7);
    } else if (url.startsWith('https://')) {
        return url;
    } else {
        return 'https://' + url;
    }
}

export function debounce(callback: any, time: number) {
    let interval: any;
    return () => {
        clearTimeout(interval)
        interval = setTimeout(() => {
                interval = null
                callback(arguments)
            }, time)
    }
}

export const trimLeadingZeroes = (inputValue: string) => {
    const trimmed = inputValue.replace(/^0+/, '');
    return trimmed === '' ? '0' : trimmed;
}

export const convertSecondsToMMSS = (seconds: number) => {
    // Use Day.js duration to handle the seconds
    const timeDuration = dayjs.duration(seconds, 'seconds');
  
    // Format minutes and seconds
    const minutes = timeDuration.minutes().toString().padStart(2, '0');
    const secs = timeDuration.seconds().toString().padStart(2, '0');
  
    // Return the formatted time string
    return `${minutes}:${secs}`;
};

export const getCurrentTimePlusNMinutes = (minutes: number) => {
    // Get current time
    const now = dayjs();
    
    // Add 15 minutes to the current time
    const futureTime = now.add(minutes, 'minute');
    
    // Convert to Unix timestamp in milliseconds
    const futureTimeInMilliseconds = futureTime.valueOf();
    
    return futureTimeInMilliseconds;
};

export const getAccountIdFromToken = (token: string | undefined): string | null => {
    try {
        const decoded =  jwt<{account_id: string}>(token || '')

        return decoded.account_id ?? null
    } catch(e) {
        return null
    }
}

export const getIsValidEmail = (email: string) => {
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailRegEx.test(email)
}

export const getFavicon = (url: string) => {
    const hostname = new URL(url).hostname
    return `https://www.google.com/s2/favicons?domain=${hostname}`
}

export const checkValidPasswordInBody = (key: string): ValidationChain => {
    return checkBody(key)
        .isLength({ min: 8, max: 48 })
        .withMessage("must be between 8 and 48 characters")
        .matches(/\d/)
        .withMessage("must include at least 1 digit")
        .matches(/[A-Z]/)
        .withMessage("must include at least 1 upper-case character")
        .matches(/[a-z]/)
        .withMessage("must include at least 1 lower-case character")
        .matches(/[.,'!&\[\]+$\-#*\\%@~`=\/^:()]/)
        .withMessage(
            "must include at least 1 special character: .,'!&[]+$-#*\\%@~`=/^:()"
        )
}

// date example: 2024-05-24T01:49:20.435Z
export const getRelativeTimeAgo = (date: string) => dayjs(date).fromNow()
