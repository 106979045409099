import { getConnectionsByCategory } from "features/connections/utils"
import { Connection, FinancialConnectionCategory, FinancialConnectionId, GetConnectionsResponse, GetConnectionsResponseWithoutGuaranteedState } from "./types"

export const bankingFinancialDataSources = [FinancialConnectionId.Plaid]
export function camelCaseToText(camelCase: string): string {
    const result = camelCase.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
}

export const getConnectionsUserHasAccessTo = ({connections, states = {states: [], external_id: ''}}: GetConnectionsResponseWithoutGuaranteedState): GetConnectionsResponse => {
    const accountingIds = getConnectionsByCategory(FinancialConnectionCategory.Accounting)
    const hasActiveAccountingConnection = states.states.some(({connection_id}) => accountingIds.includes(connection_id))
    const filteredConnections = connections.filter(({id}) => {
        if (hasActiveAccountingConnection && accountingIds.includes(id)) {
            return states.states.some(({connection_id}) => connection_id === id)
        }

        return true
    })
    return {connections: filteredConnections, states}
}

export const getAllConnections = ({connections}: GetConnectionsResponseWithoutGuaranteedState): Connection[] => connections

export const getDatesQueryString = ({start_date, end_date}: {start_date: string, end_date: string}) => {
    const startDate = new Date(start_date).toISOString().split('T')[0]
    const endDate = new Date(end_date).toISOString().split('T')[0]

    return `&start_date=${startDate}&end_date=${endDate}`
}
