import Button from "components/common/Button"

interface Props {
    onClose: () => void
}

export default function AcknowledgeBillingCancelModal({onClose}: Props) {
    return (
        <div className="flex flex-col gap-5 items-center pb-10 px-10">
            <div className="flex flex-col items-center gap-[10px]">
                <div className="text-[#1F2937] text-2xl font-semibold tracking-[0.12px]">
                    Payment Cancelled
                </div>
                <div className="text-[#6B7280] text-[16px] font-normal leading-[24px] tracking-[0.08px]">
                    Payment has been cancelled and you will not be charged
                </div>
            </div>
            <div className="flex gap-4">
                <Button
                    small
                    plain
                    onClick={onClose}
                    text="Confirm"
                />
            </div>
        </div>
    )
}