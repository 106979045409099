import React, { useState } from "react"
import Lottie from "lottie-react"
import error_1 from "assets/lotties/error_1.json"
import error_2 from "assets/lotties/error_2.json"
import error_3 from "assets/lotties/error_3.json"
import { Link } from "react-router-dom"

function ErrorScreen() {
    // TODO Integrate with Sentry
    const lotties = [error_1, error_2, error_3]
    const [lottie, setLottie] = useState(
        lotties[Math.floor(Math.random() * lotties.length)]
    )

    return (
        <div className="flex flex-col w-full flex-grow p-10 items-center justify-center gap-2.5 overflow-y-auto">
            <Lottie
                animationData={lottie}
                loop={true}
                className="w-[402px] h-[345px]"
            />
            <div className="flex flex-col items-center gap-2.5">
                <div className="text-center text-title-1 text-[40px] font-bold tracking-tight">
                    Uh oh
                </div>
                <div className="flex flex-col items-center gap-[3px]">
                    <div className="text-center text-gray-700 text-2xl font-medium leading-normal tracking-tight">
                        Something went wrong at our end
                    </div>
                    <div className="text-center text-slate-500 text-base leading-normal tracking-tight font-medium">
                        Don’t worry its not you - It’s us. Sorry about that.
                    </div>
                </div>{" "}
                <button className="px-5 py-2.5 bg-secondary-blue rounded-[5px] justify-center items-center gap-[5px] inline-flex hover:outline-none">
                    <a
                        href={"/"}
                        className="text-center text-white text-sm font-semibold leading-snug"
                    >
                        Back to Home
                    </a>
                </button>
            </div>
        </div>
    )
}

export default ErrorScreen
