import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetLoggedInUserAccountsResponseAccount } from "./types"
import { accountsAPI } from "./accountsAPI"
import { RootState } from "redux/store"
import { getAccountSelectionForUser, saveAccountSelectionForUser } from "./utils"

interface AccountsState {
    currentWorkspaceAccountId: string | null
    currentUserAccounts: GetLoggedInUserAccountsResponseAccount[]
}

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        currentWorkspaceAccountId: null,
        currentUserAccounts: [],
    } as AccountsState,
    reducers: {
        setActiveWorkspace(state, {payload: {userId, accountId}}: PayloadAction<{accountId: string, userId: string}>) {
            saveAccountSelectionForUser(userId, accountId)

            return {
                ...state,
                currentWorkspaceAccountId: accountId,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(accountsAPI.endpoints.getAccountsCurrentUserHasAccessTo.matchFulfilled, ({currentWorkspaceAccountId}, {payload: {accounts, user_id}}) => ({
                currentUserAccounts: accounts,
                currentWorkspaceAccountId: (() => {
                    if (currentWorkspaceAccountId) {
                        return currentWorkspaceAccountId
                    }

                    const storedAccount = getAccountSelectionForUser(user_id)

                    if (storedAccount) {
                        const hasAccessToStoredAccount = accounts.find(({id}) => id === storedAccount)
                        if (hasAccessToStoredAccount) {
                            return storedAccount
                        }

                        return null
                    }

                    if (accounts.length > 0) {
                        return accounts[0].id
                    }

                    return null
                })(),
            }))
    },
})

export default accountsSlice.reducer
export const {setActiveWorkspace} = accountsSlice.actions
export const accountsState = (state: RootState) => state.accounts
