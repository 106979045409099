import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep'
import mixpanel from 'mixpanel-browser';
import { MixpanelEvent, Session, SessionName, TrackedRequestActionType } from './types';

export const thirtyMinutes = 30 * 60 * 1000

export const createSession = (name: SessionName): Session => {
    const session = {
        id: uuid(),
        name,
        created_at: Date.now(),
        updated_at: Date.now(),
        sessions: [],
    }

    try {
        mixpanel.track(MixpanelEvent.SESSION_INITIATED, {
            [`${name}-session-id`]: session.id,
        })
    } catch(e) {
        console.log(e)
    }

    return session
}

const sendCompletedSessionToMixpanel = ({created_at, updated_at, name, id}: Session) => {
    try {
        mixpanel.track(MixpanelEvent.SESSION_COMPLETED, {
            duration_millis: updated_at - created_at,
            [`${name}-session-id`]: id,
        })
    } catch(e) {
        console.log(e)
    }
}

export const traverseAndUpdateSession = (rootSession: Session | null, name: SessionName): Session => {
    if (!rootSession) {
        return createSession(name)
    }

    const thirtyMinutesAgo = Date.now() - thirtyMinutes;
    let found = false

    const searchAndUpdate = (currentSession: Session) => {
        let session = cloneDeep(currentSession)

        // if session is stale, create a new session and send event to mixpanel
        if (session.updated_at < thirtyMinutesAgo) {
            sendCompletedSessionToMixpanel(session)
            const sessions = cloneDeep(session.sessions)
            session = createSession(session.name)
            session.sessions = sessions
        }

        if (session.name === name) {
            found = true
        }

        session.sessions = session.sessions.map(searchAndUpdate)
        
        if (session.name === SessionName.APOKTO && !found) {
            // create a session in root ancestor if not found
            session.sessions.push(createSession(name))
        }

        if (found) {
            // update current session and all ancestors if found
            session.updated_at = Date.now()
        }

        return session
    }

    return searchAndUpdate(cloneDeep(rootSession))
}

export const getQueryParamsFromSession = (rootSession: Session, name: SessionName): string => {
    let found = false
    let params: string[] = []

    const searchAndConcatenate = (session: Session) => {
        if (session.name === name) {
            found = true
        } else {
            for (let i = 0; i < session.sessions.length; i++) {
                if (found) {
                    break
                }

                searchAndConcatenate(session.sessions[i])
            }
        }

        if (found) {
            params.push(`${session.name}_session_id:${session.id}`)
        } else {
            session.sessions.push(createSession(name))
        }
    }

    searchAndConcatenate(rootSession)

    return `session=${params.join(',')}`
}

export const getSessionIdsFromSession = (rootSession: Session, name: SessionName): Record<string, string> => {
    let found = false
    let sessionIds: Record<string, string> = {}

    const searchAndConcatenate = (session: Session) => {
        if (session.name === name) {
            found = true
        } else {
            for (let i = 0; i < session.sessions.length; i++) {
                if (found) {
                    break
                }

                searchAndConcatenate(session.sessions[i])
            }
        }

        if (found) {
            sessionIds[`${session.name}_session_id`] = session.id
        } else {
            session.sessions.push(createSession(name))
        }
    }

    searchAndConcatenate(rootSession)

    return sessionIds
}

export const getSessionNameFromRequestType = (requestType: TrackedRequestActionType): SessionName => ({
    [TrackedRequestActionType.ZOE_MUTATION_ACTION]: SessionName.ZOE,
})[requestType]
