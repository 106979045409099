import { FinancialConnectionCategory, FinancialConnectionId, GetConnectionsRequest, GetConnectionsResponse, StatusElement } from "features/financials/types";
import freshbooks from 'assets/svg/connections/freshbooks.svg'
import microsoftDynamics from 'assets/svg/connections/microsoft-dynamics.svg'
import oracleNetSuite from 'assets/svg/connections/oracle-net-suite.svg'
import quickbooks from 'assets/svg/connections/quickbooks.svg'
import sageBusinessCloud from 'assets/svg/connections/sage-business-cloud.svg'
import sageIntacct from 'assets/svg/connections/sage-intacct.svg'
import xero from 'assets/svg/connections/xero.svg'
import wave from 'assets/svg/connections/wave.svg'
import plaid from 'assets/svg/connections/plaid.svg'
import shopify from 'assets/svg/connections/shopify.svg'
import square from 'assets/svg/connections/square.svg'
import stripe from 'assets/svg/connections/stripe.svg'
import zohoBooks from 'assets/svg/connections/zoho-books.svg'
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { PotentialConnection } from "components/Companies/Create/types";
dayjs.extend(relativeTime)

export const Categories: {
    name: string
    value?: GetConnectionsRequest["category"]
}[] = [
    { name: "All", value: undefined },
    { name: "Accounting", value: "accounting" },
    { name: "Banking", value: "banking" },
    { name: "Commerce", value: "commerce" },
    { name: "Payment Processing", value: "payment_processing" },
]

export const getConnectionIcon = (connectionId: FinancialConnectionId): string => ({
    [FinancialConnectionId.FreshBooks]: freshbooks,
    [FinancialConnectionId.MicrosoftDynamics365BusinessCentral]: microsoftDynamics,
    [FinancialConnectionId.OracleNetSuite]: oracleNetSuite,
    [FinancialConnectionId.QuickBooksDesktop]: quickbooks,
    [FinancialConnectionId.QuickBooksOnline]: quickbooks,
    [FinancialConnectionId.SageBusinessCloud]: sageBusinessCloud,
    [FinancialConnectionId.SageIntacct]: sageIntacct,
    [FinancialConnectionId.Xero]: xero,
    [FinancialConnectionId.Wave]: wave,
    [FinancialConnectionId.Plaid]: plaid,
    [FinancialConnectionId.Shopify]: shopify,
    [FinancialConnectionId.Square]: square,
    [FinancialConnectionId.Stripe]: stripe,
    [FinancialConnectionId.ZohoBooks]: zohoBooks,
} as Record<FinancialConnectionId, string>)[connectionId]

export const getLastSync = (statuses?: StatusElement[]): string => {
    const lastSync = statuses?.length ? statuses[0].last_sync_date : null
    const timeAgo = dayjs(lastSync).fromNow() 
    return lastSync ? `Last sync ${timeAgo}` : ''
}

export const getConnectionsByCategory = (category: FinancialConnectionCategory): FinancialConnectionId[] => ({
    [FinancialConnectionCategory.Accounting]: [
        FinancialConnectionId.FreshBooks,
        FinancialConnectionId.MicrosoftDynamics365BusinessCentral,
        FinancialConnectionId.OracleNetSuite,
        FinancialConnectionId.QuickBooksDesktop,
        FinancialConnectionId.QuickBooksOnline,
        FinancialConnectionId.SageBusinessCloud,
        FinancialConnectionId.SageIntacct,
        FinancialConnectionId.Xero,
        FinancialConnectionId.Wave,
        FinancialConnectionId.ZohoBooks,
    ],
    [FinancialConnectionCategory.Banking]: [
        FinancialConnectionId.Plaid,
    ],
    [FinancialConnectionCategory.Commerce]: [
        FinancialConnectionId.Shopify,
        FinancialConnectionId.Square,
    ],
    [FinancialConnectionCategory.PaymentProcessing]: [
        FinancialConnectionId.Stripe
    ]
})[category]

export interface CategoryNameToId {
    'Accounting': FinancialConnectionCategory.Accounting,
    'Banking': FinancialConnectionCategory.Banking,
    'Commerce': FinancialConnectionCategory.Commerce,
    'Payment Processing': FinancialConnectionCategory.PaymentProcessing
}

export const categoryNameToId: CategoryNameToId = {
    'Accounting': FinancialConnectionCategory.Accounting,
    'Banking': FinancialConnectionCategory.Banking,
    'Commerce': FinancialConnectionCategory.Commerce,
    'Payment Processing': FinancialConnectionCategory.PaymentProcessing
}

export const filterConnectionsByCategory = (connections: GetConnectionsResponse, category: string): GetConnectionsResponse => {
    if (category === 'All') {
        return connections
    }

    const connectionIds = getConnectionsByCategory(categoryNameToId[category as keyof CategoryNameToId])

    return {
        states: connections.states,
        connections: connections.connections.filter(({id}) => connectionIds.includes(id))
    }
}

export const getIsAccountingConnection = (connectionId: FinancialConnectionId): boolean => [
    FinancialConnectionId.FreshBooks,
    FinancialConnectionId.MicrosoftDynamics365BusinessCentral,
    FinancialConnectionId.OracleNetSuite,
    FinancialConnectionId.QuickBooksDesktop,
    FinancialConnectionId.QuickBooksOnline,
    FinancialConnectionId.SageBusinessCloud,
    FinancialConnectionId.SageIntacct,
    FinancialConnectionId.Xero,
    FinancialConnectionId.Wave,
    FinancialConnectionId.ZohoBooks,
].includes(connectionId)

export const getShouldRenderConnection = (
    potentialConnections: PotentialConnection[],
    currentConnectionId: FinancialConnectionId,
) => {
    if (potentialConnections.some(({id}) => id === currentConnectionId)) {
        return true
    }

    const hasAccountingSelected = potentialConnections.some(({id}) => getIsAccountingConnection(id))
    const accountingConnections = getConnectionsByCategory(FinancialConnectionCategory.Accounting)

    return hasAccountingSelected ? !accountingConnections.includes(currentConnectionId) : true
}

export const getAreDashboardAvailable = (connectionId: FinancialConnectionId) => [
    FinancialConnectionId.FreshBooks,
    FinancialConnectionId.OracleNetSuite,
    FinancialConnectionId.QuickBooksOnline,
    FinancialConnectionId.SageBusinessCloud,
    FinancialConnectionId.SageIntacct,
    FinancialConnectionId.Xero,
    FinancialConnectionId.ZohoBooks,
].includes(connectionId)
