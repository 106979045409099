import mixpanel, {Config} from "mixpanel-browser"
import { ENV } from "config"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { currentUser } from "features/authentication/authenticationSlice";

const mixpanelConfig: Partial<Config> = {
    debug: true,
    persistence: 'localStorage',
    ignore_dnt: true,
}

export default function Mixpanel() {
    const user = useSelector(currentUser)

    useEffect(() => {
        mixpanel.init(ENV.MIXPANEL_TOKEN, mixpanelConfig);
    }, [])

    useEffect(() => {
        if (user) {
            mixpanel.identify(user.sub)
        }
    }, [user])

    return null
}
