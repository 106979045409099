import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"

interface FeedbackModalProps {
    isPositive: boolean
    messageId: number
    conversationId: string
}

interface DeleteConversationProps {
    conversationId: string
    companyId: string
}

const zoe = createSlice({
    name: 'zoe',
    initialState: {
        activeConversationIds: {} as Record<string, string | null>,
        feedbackModal: null as FeedbackModalProps | null,
        deleteConversationModal: null as DeleteConversationProps | null,
        pendingMessage: null as string | null,
    },
    reducers: {
        setFeedbackModal: (state, action: PayloadAction<null | FeedbackModalProps>) => ({
            ...state,
            feedbackModal: action.payload,
        }),
        setDeleteConversationModal: (state, action: PayloadAction<null | DeleteConversationProps>) => ({
            ...state,
            deleteConversationModal: action.payload,
        }),
        setPendingMessage: (state, action: PayloadAction<string | null>) => ({
            ...state,
            pendingMessage: action.payload,
        }),
        setActiveConversationId: (state, action: PayloadAction<{companyId: string, conversationId: string | null}>) => ({
            ...state,
            activeConversationIds: {
                ...state.activeConversationIds,
                [action.payload.companyId]: action.payload.conversationId,
            },
        }),
    },
})

export const {
    setFeedbackModal,
    setDeleteConversationModal,
    setActiveConversationId,
    setPendingMessage,
} = zoe.actions
export const zoeState = (state: RootState) => state.zoe
export default zoe.reducer
