import { CompanyCreationStep } from "features/companies/types"

// Get Financial connections
export interface GetConnectionsRequest {
    category?: "accounting" | "banking" | "commerce" | "payment_processing"
    companyId?: string
    filterAccounting?: boolean
}

export interface GetConnectionsResponse {
    connections: Connection[]
    states: States
}

export interface GetConnectionsResponseWithoutGuaranteedState {
    connections: Connection[]
    states?: States
}

export interface Connection {
    id: FinancialConnectionId
    display_name: string
    name: string
    description: string
    is_enabled: boolean
    rutter_id: string
}

export interface States {
    external_id: string
    states: { connection_id: FinancialConnectionId; state: ConnectionStateId }[]
}
export enum ConnectionStateId {
    /**
     * Authorization started but not completed by business (default status). No
     * data will be available at this stage.
     */
    Pending = "pending",
    /**
     * Authorization is completed successfully by the business and you should be
     * able to retrieve its data.
     */
    Active = "active",
    /**
     * The connection was disconnected via the dashboard, API or based on service
     * provider webhooks. You will still be able to access any data that was
     * already synchronized, but you won't be able to retrieve any new data until
     * the business completes the authorization process again.
     */
    Disconnected = "disconnected",
    /**
     * Connection failed when attempting to get data from a previously valid
     * credential. You will still be able to access any data that was already
     * synchronized, but you won't be able to retrieve any new data until the
     * business completes the authorization process again.
     */
    Expired = "expired",
    /**
     * Was never able to transition from pending to active based on credentials.
     * No data will be available at this stage.
     */
    Invalid = "invalid",
    /**
     * Created by Apokto, this state is a catch-all for when the data we consume
     * about the state of a connection gives us a state we don't know about.
     */
    Unknown = "unknown",
}
export enum FinancialConnectionCategory {
    Accounting = 1 << 0,
    Banking = 1 << 1,
    Commerce = 1 << 2,
    PaymentProcessing = 1 << 3,
}

export enum FinancialConnectionId {
    FreshBooks = FinancialConnectionCategory.Accounting + (1 << 8),
    MicrosoftDynamics365BusinessCentral = FinancialConnectionCategory.Accounting +
        (2 << 8),
    OracleNetSuite = FinancialConnectionCategory.Accounting + (3 << 8),
    QuickBooksDesktop = FinancialConnectionCategory.Accounting + (4 << 8),
    QuickBooksOnline = FinancialConnectionCategory.Accounting + (5 << 8),
    SageBusinessCloud = FinancialConnectionCategory.Accounting + (6 << 8),
    SageIntacct = FinancialConnectionCategory.Accounting + (7 << 8),
    Xero = FinancialConnectionCategory.Accounting + (8 << 8),
    Wave = FinancialConnectionCategory.Accounting + (9 << 8),
    ZohoBooks = FinancialConnectionCategory.Accounting + (10 << 8),

    Plaid = FinancialConnectionCategory.Banking + (1 << 8),

    Shopify = FinancialConnectionCategory.Commerce + (1 << 8),
    Square = FinancialConnectionCategory.Commerce + (2 << 8),

    Stripe = FinancialConnectionCategory.PaymentProcessing + (1 << 8),
}

export const FinancialConnectionIdToAPIEnumerationMap: {
    [key: number]: string
} = {
    [FinancialConnectionId.FreshBooks]: "freshbooks",
    [FinancialConnectionId.MicrosoftDynamics365BusinessCentral]:
        "dynamicsBusinessCentral",
    [FinancialConnectionId.OracleNetSuite]: "oracleNetsuite",
    [FinancialConnectionId.QuickBooksDesktop]: "quickbooksDesktop",
    [FinancialConnectionId.QuickBooksOnline]: "quickbooks",
    [FinancialConnectionId.SageBusinessCloud]: "sageBusinessCloud",
    [FinancialConnectionId.SageIntacct]: "sageIntacct",
    [FinancialConnectionId.Xero]: "xero",
    [FinancialConnectionId.Wave]: "wave",

    [FinancialConnectionId.Plaid]: "plaid",

    [FinancialConnectionId.Shopify]: "shopify",
    [FinancialConnectionId.Square]: "square",
}
// Create pending req
export interface CreatePendingFinancialConnectionRequest {
    company_id: string
    connection_id: number
    current_step?: CompanyCreationStep
}

// Update pending request
export interface UpdateFinancialConnectionResponse {
    invitation: Invitation
    operation: number
    other_invitations_modified: Invitation[]
}

export interface Invitation {
    id: string
    company_id: string
    invitee_email: string
    state: number
    expires_at: Date
    connections_to_authenticate: string[]
}

// Connection sync status

export interface SyncConnectionResponse {
    statuses: StatusElement[]
}

export interface StatusElement {
    connection_id: number
    data_type_id: string
    last_update_date: Date
    reports: Report[]
    last_sync_date?: Date
}

export interface Report {
    status: SyncStatusEnum
    is_synthetic: boolean
    start_date?: Date
    end_date?: Date
    report_frequency?: ReportFrequency
    latest_successful_report_id?: string
}

export enum ReportFrequency {
    Month = "month",
    Quarter = "quarter",
    Year = "year",
}

export enum SyncStatusEnum {
    Pending = "pending",
    Success = "success",
    Failed = "failed",
    Empty = "empty",
}

// Reports
export interface GetReportsRequest {
    company_id: string
    start_date: string
    end_date: string
}

export interface PatchValuationRequestBody {
    ebitda_multiple?: number
    discount_rate?: number
}

export interface FinancialReportDataPoint {
    date: string
    value: string
}
export interface DashboardNamedDataPoint {
    name: string
    value: string
}
export interface IncomeDashboardModel {
    summaries: DashboardSummaryChartModel[]
    sections: IncomeDashboardSection[]
    income_statement: IncomeStatementModel
    cashflow_statement: CashflowStatementModel
    balance_sheet: DashboardNamedDataRangeChartModel
    margins: MarginsModel
}

export interface DashboardNamedDataRangeChartModel {
    title?: string
    series: DashboardNamedDataRange[]
}

export interface DashboardNamedDataRange {
    label: string
    low: string
    high: string
}

export interface CashflowStatementModel {
    operating_series: FinancialReportDataPoint[]
    investing_series: FinancialReportDataPoint[]
    financing_series: FinancialReportDataPoint[]
}

export interface IncomeStatementModel {
    revenue_series: FinancialReportDataPoint[]
    net_income_series: FinancialReportDataPoint[]
    profit_margin_series: FinancialReportDataPoint[]
}

export interface MarginsModel {
    revenue_series: FinancialReportDataPoint[]
    ebitda_margin_series: FinancialReportDataPoint[]
    gross_margin_series: FinancialReportDataPoint[]
}

export interface IncomeDashboardSection {
    title: string
    total?: string
    summaries: DashboardSummaryChartModel[]
    total_annotation?: string
    series: FinancialReportDataPoint[]
    change?: string
}

export interface DashboardSummaryChartModel {
    label: string
    total?: string
    series: FinancialReportDataPoint[]
    change?: string
}

// Expense
export interface ExpenseDashboardModel {
    summaries: DashboardSummaryChartModel[]
    cost_of_revenue?: DashboardMultipleNamedSeriesModel
    expense_composition?: DashboardMultipleNamedSeriesModel
    value_of_untagged_expenses?: DashboardSummaryChartModel
    expense_changes?: ExpenseChangesChartModel
    operating_expenses?: ExpensesOperatingExpensesModel
    expenses_as_percentage_of_revenue_model?: DashboardMultipleNamedSeriesModel
    cash_outflow_over_time?: CashOutflowOverTimeModelChartModel
    unusual_expense_detection?: UnusualExpenseDetectionChartModel
    top_vendors?: TopVendorsChartModel
}

export interface TopVendorsChartModel {
    name: string
    data: NamedExpense[]
}

export interface DashboardNamedDateValue {
    name: string
    date: string
    value: string
}

export interface UnusualExpenseDetectionChartModel {
    name: string
    series: DashboardNamedDateValue[]
}

export interface CashOutflowOverTimeModelChartModel {
    name: string
    series: FinancialReportDataPoint[]
}

export interface ExpenseChangesChartModel {
    change: string
    label: string
    total: string
    current_period: FinancialReportDataPoint[]
    previous_period: FinancialReportDataPoint[]
}

export interface DashboardMultipleNamedSeriesModel {
    series: DashboardNamedSeries[]
}
export interface DashboardNamedSeries {
    name: string
    series: FinancialReportDataPoint[]
}

export interface ExpensesOperatingExpensesModel {
    name: string
    data: NamedExpense[]
}

export interface DashboardNamedDataPointWithChange {
    data_point: DashboardNamedDataPoint
    change?: string
}

// Income Statement
export interface IncomeStatement {
    entries: IncomeStatementEntry[]
}

export interface IncomeStatementEntry {
    date: string
    revenue: IncomeStatementRevenue
    ebitda: IncomeStatementEBITDA
    ebit: IncomeStatementEBIT
    ebt: IncomeStatementEBT
    taxes: IncomeStatementTaxes
    net_income: string
}

export interface IncomeStatementEBIT {
    non_cash_expenses: string
    ebit: string
}

export interface IncomeStatementEBITDA {
    operating_expenses: NamedExpense[]
    ebitda: string
}

export interface NamedExpense {
    name: string
    value: string
}

export interface IncomeStatementEBT {
    interest_expense: string
    non_operating_income: string
    non_operating_expenses: string
    ebt: string
}

export interface IncomeStatementRevenue {
    revenue: string
    cogs: string
    gross_profit: string
}

export interface IncomeStatementTaxes {
    current_tax: string
    deferred_tax: string
    total_tax: string
}

// Cashflow Statement
export interface CashflowStatement {
    entries: CashflowStatementEntry[]
}

export interface CashflowStatementEntry {
    date: string
    operating: CashflowStatementOperations
    investing: CashflowStatementInvesting
    financing: CashflowStatementFinancing
    balance: CashflowStatementBalance
}

export interface CashflowStatementBalance {
    beginning: string
    change: string
    ending: string
}

export interface CashflowStatementFinancing {
    change_in_long_term_debt: string
    change_in_share_capital: string
    dividends: string
    total: string
}

export interface CashflowStatementInvesting {
    capital_expenditures: string
    total: string
}

export interface CashflowStatementOperations {
    net_income: string
    non_cash_expenses: string
    change_nwc: string

    total: string
}

// Balance sheet
export interface BalanceSheet {
    entries: BalanceSheetEntry[]
}

export interface BalanceSheetEntry {
    date: string
    assets: Assets
    liabilities: Liabilities
    equity: Equity
    check: string
}

export interface Assets {
    current_assets: CurrentAssets
    non_current_assets: NonCurrentAssets
    total: string
}
export interface CurrentAssets {
    cash: string
    accounts_receivable: string
    inventory: string
    other_current_assets: string
    total: string
}

export interface NonCurrentAssets {
    fixed_assets: string
    accumulated_depreciation: string
    other_non_current_assets: string
    total: string
}
export interface Equity {
    share_capital: string
    retained_earnings: string

    total: string
}

export interface Liabilities {
    current_liabilities: CurrentLiabilities
    non_current_liabilities: NonCurrentLiabilities
    total: string
}
export interface CurrentLiabilities {
    accounts_payable: string
    current_debt: string
    tax_liabilities: string
    other_current_liabilities: string
    total: string
}

export interface NonCurrentLiabilities {
    long_term_debt: string
    total: string
}

// Valuation
export interface ValuationDashboard {
    dcf: DCFEntry[]
    value: ValueSection
    sensitivity_tables: SensitivityTable[]
    cashflow_chart: DashboardMultipleNamedSeriesModel
    value_chart: DashboardNamedDataPoint[]
    ebitda_multiple: string
    discount_rate: string
}

export interface CashflowChartSeries {
    name: string
    series: SeriesSeries[]
}

export interface SeriesSeries {
    date: Date
    value: string
}

export interface DCFEntry {
    name: string
    // period: number
    date: string
    revenue: string
    revenue_growth: string
    ebitda: string
    ebitda_margin: string
    ebit: string
    ebit_margin: string
    taxes: string
    tax_rate_ebit: string
    ebiat: string
    non_cash_expenses: string
    capex: string
    change_nwc: string
    unlevered_fcf: string
    discount_period: string
    discount_factor: string
    pv_fcf: string
}

export interface SensitivityTable {
    title: string
    ebitda_multiples: string[]
    discount_rates: string[]
    values: Array<SensitivityCell[]>
}

export interface SensitivityCell {
    ebitda_multiple: string
    discount_rate: string
    value: string
}

export interface ValueSection {
    exit_date: string
    terminal_ebitda: string
    discount_rate: string
    ebitda_multiple: string
    terminal_value: string
    discount_factor: string
    pv_terminal_value: string
    cumulative_pv_fcf: string
    enterprise_value: string
    total_debt: string
    total_cash: string
    equity_value: string
    implied_pgr: string
    implied_ebitda_multiple: string
}
