import { useDeleteCompanyMutation } from "features/companies/companiesAPI"
import { Company } from "features/companies/types"
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { APIError, ServerError } from "types/errors"
import deleteIcon from "assets/svg/company-settings/delete-modal.svg"
import Button from "components/common/Button"
import { useDispatch, useSelector } from "react-redux"
import { accountsState } from "features/accounts/accountsSlice"
import { removeRecentCompany } from "features/recentCompanies/recentCompaniesSlice"

interface Props {
    onClose: () => void
    onComplete: () => void
    company: Partial<Company>
}

export default function DeleteModal({onClose, onComplete, company}: Props) {
    const dispatch = useDispatch()
    const {currentWorkspaceAccountId} = useSelector(accountsState)
    const [deleteCompany, {isLoading: isDeleting}] = useDeleteCompanyMutation()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [hasDeleted, setHasDeleted] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const isValid = inputValue === company.name

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef.current])

    const handleDelete = () => {
        deleteCompany(company.id!).unwrap()
            .then(() => {
                setHasDeleted(true)
                dispatch(removeRecentCompany({accountId: currentWorkspaceAccountId!, companyId: company.id!}))
            })
            .catch(({data}: APIError) => data.forEach(({msg}: ServerError) => toast.error(msg)))
    }

    const handleEnterKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !event.shiftKey && isValid) {
            event.preventDefault()
            handleDelete()
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
    }

    return hasDeleted ? (
        <div className="flex flex-col gap-5 items-center pb-10 px-10">
            <img className="h-16 w-16" src={deleteIcon} />
            <div className="flex flex-col items-center gap-[10px]">
                <div className="text-[#1F2937] text-2xl font-bold tracking-[0.12px]">
                    {`${company.name} deleted`}
                </div>
                <div className="text-base font-medium tracking-[0.08px] text-center text-[#6B7280]">
                    Your company, “{company.name}” has been deleted successfully on Apokto servers. Please e-mail&nbsp;
                    <a href="mailto:support@apokto.io" className="text-blue-500">support@apokto.io</a>
                    &nbsp;if you have any questions
                </div>
            </div>
            <Button
                small
                text="Got it"
                onClick={onComplete}
            />
        </div>
    ) : (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-5 items-center px-10">
                <img className="h-16 w-16" src={deleteIcon} />
                <div className="w-full flex flex-col items-center gap-[10px]">
                    <div className="text-[#1F2937] text-2xl font-semibold tracking-[0.12px]">
                        Confirm Delete
                    </div>
                    <div className="text-[#6B7280] text-[16px] font-normal leading-[24px] tracking-[0.08px]">
                        {`Type “${company.name}” to delete`}
                    </div>
                    <input
                        ref={inputRef}
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleEnterKeyPress}
                        className="outline-none py-3 pl-4 pr-9 w-full border border-slate-200 rounded-md text-sm focus:border-secondary-blue shadow-sm"
                    />
                </div>
            </div>
            <div className="w-full flex items-center justify-center p-5 rounded-b-xl">
                {isValid && (
                    <Button
                        color="red"
                        small
                        loading={isDeleting}
                        text="Permanently Delete"
                        onClick={handleDelete}
                    />
                )}
            </div>
        </div>
    )
}
