import { ReactElement, ReactNode } from "react"

type InputTypes = {
    label?: string | ReactNode
    className?: string
    value?: boolean
    onChange?: (value: boolean) => any
    onClick?: () => any
    error?: boolean
    errorMessage?: string
    type?: "checkbox" | "radio"
    containerClassName?: string
}

export default function Checkbox({
    label = "",
    className = "",
    value: checked = false,
    onChange = () => {},
    onClick = () => {},
    error = false,
    errorMessage = "",
    type = "checkbox",
    containerClassName = "",
    ...props
}: InputTypes): ReactElement {
    return (
        <div className={`flex items-center gap-3 ${containerClassName}`}>
            <input
                type={type}
                data-cy="checkbox-element"
                className={`shrink-0 border-slate-200 rounded text-blue-600 cursor-pointer ${className} ${
                    error && "border-red-600"
                }`}
                id={`hs-checkbox-group-${label}`}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                onClick={() => onClick()}
                {...props}
            />
            <div className={`flex flex-col items-start justify-end`}>
                <label
                    htmlFor={`hs-checkbox-group-${label}`}
                    className="text-sm text-slate-500 cursor-pointer"
                >
                    {label}
                </label>

                {error && (
                    <p
                        className="text-sm text-red-600 mt-2"
                        data-cy="error-message"
                        id="hs-validation-name-error-helper"
                    >
                        {errorMessage}
                    </p>
                )}
            </div>
        </div>
    )
}
