import SpinningLoader from "components/_shared/SpinningLoader/SpinningLoader"
import { CSSProperties, PropsWithChildren } from "react"

type ButtonSize = 'small' | 'default'
type ButtonColor = 'blue' | 'red' | 'black' | 'slate'
type ButtonVariant = 'solid' | 'plain' | 'outlined'
type ButtonState = 'enabled' | 'disabled'

interface Props extends PropsWithChildren {
    style?: CSSProperties
    text?: string
    onClick?: (e: any) => any
    loading?: boolean
    outlined?: boolean
    plain?: boolean
    disabled?: boolean
    color?: ButtonColor 
    small?: boolean
    className?: string
    type?: 'button' | 'submit' | 'reset'
}

interface Colors{
    text: Record<ButtonVariant, Record<ButtonState, Record<ButtonColor, string>>>
    background: Record<ButtonVariant, Record<ButtonState, Record<ButtonColor, string>>>
    border: Record<ButtonVariant, Record<ButtonState, Record<ButtonColor, string>>>
}

// tailwind requires the entire class as a variable
const colors: Colors = {
    text: {
        solid: {
            enabled: {
                blue: 'text-white',
                red: 'text-white',
                black: 'text-white',
                slate: 'text-white',
            },
            disabled: {
                blue: 'text-[#EFF6FF]',
                red: 'text-[#EFF6FF]',
                black: 'text-[#D1D5DB]',
                slate: 'text-white',
            }
        },
        plain: {
            enabled: {
                blue: 'hover:text-[#1D4ED8] text-[#005EFF]',
                red: 'text-[#EF4444]',
                black: 'text-[#1F2937]',
                slate: 'text-white',
            },
            disabled: {
                blue: 'text-[#BFDBFE]',
                red: 'text-[#FECACA]',
                black: 'text-[#D1D5DB]',
                slate: 'text-white',
            }
        },
        outlined: {
            enabled: {
                blue: 'text-[#005EFF]',
                red: 'text-[#EF4444]',
                black: 'text-[#1F2937]',
                slate: 'text-white',
            },
            disabled: {
                blue: 'text-[#B7D2FF]',
                red: 'text-[#F87171]',
                black: 'text-[#D1D5DB]',
                slate: 'text-white',
            }
        }
    },
    background: {
        solid: {
            enabled: {
                blue: 'hover:bg-[#0040AD] bg-[#005EFF]',
                red: 'hover:bg-[#DC2626] bg-[#EF4444]',
                black: 'hover:bg-[#111827] bg-[#1F2937]',
                slate: 'bg-[#475569]',
            },
            disabled: {
                blue: 'bg-[#B7D2FF]',
                red: 'bg-[#F87171]',
                black: 'bg-[#4B5563]',
                slate: 'bg-[#475569]',
            }
        },
        plain: {
            enabled: {
                blue: 'hover:bg-[#F8FAFC] bg-white',
                red: 'hover:bg-[#F8FAFC] bg-white',
                black: 'hover:bg-[#F8FAFC] bg-white',
                slate: 'bg-[#475569]',
            },
            disabled: {
                blue: 'bg-white',
                red: 'bg-white',
                black: 'bg-white',
                slate: 'bg-[#475569]',
            }
        },
        outlined: {
            enabled: {
                blue: 'bg-transparent',
                red: 'bg-transparent',
                black: 'bg-transparent',
                slate: 'bg-[#475569]',
            },
            disabled: {
                blue: 'bg-transparent',
                red: 'bg-transparent',
                black: 'bg-transparent',
                slate: 'bg-[#475569]',
            }
        }
    },
    border: {
        solid: {
            enabled: {
                blue: 'hover:border-[#0040AD] border-[#005EFF]',
                red: 'hover:border-[#DC2626] border-[#EF4444]',
                black: 'hover:border-[#111827] border-[#1F2937]',
                slate: 'bg-[#475569]',
            },
            disabled: {
                blue: 'border-[#B7D2FF]',
                red: 'border-[#F87171]',
                black: 'border-[#4B5563]',
                slate: 'bg-[#475569]',
            }
        },
        plain: {
            enabled: {
                blue: 'border-[#E5E7EB]',
                red: 'border-[#E5E7EB]',
                black: 'border-[#E5E7EB]',
                slate: 'bg-[#475569]',
            },
            disabled: {
                blue: 'border-[#F3F4F6]',
                red: 'border-[#F3F4F6]',
                black: 'border-[#F3F4F6]',
                slate: 'bg-[#475569]',
            }
        },
        outlined: {
            enabled: {
                blue: 'border-[#005EFF]',
                red: 'border-[#EF4444]',
                black: 'border-[#E5E7EB]',
                slate: 'bg-[#475569]',
            },
            disabled: {
                blue: 'border-[#B7D2FF]',
                red: 'border-[#F87171]',
                black: 'border-[#F3F4F6]',
                slate: 'bg-[#475569]',
            }
        }
    }
}

const padding: Record<ButtonSize, string> = {
    small: 'py-[9px] px-[11px]',
    default: 'py-[13px] px-[15px]',
}

const getStyles = (
    variantKey: 'solid' | 'plain' | 'outlined',
    activeKey: 'enabled' | 'disabled',
    size: ButtonSize,
    color: ButtonColor,
    className: string,
) => [
    colors.text[variantKey][activeKey][color],
    colors.background[variantKey][activeKey][color],
    colors.border[variantKey][activeKey][color],
    padding[size],
    activeKey === 'disabled' ? '' : 'cursor-pointer',
    'transition-all inline-flex gap-1 rounded-[8px] text-15px leading-[18px] font-normal tracking-[0.075px] whitespace-nowrap w-min h-min border-[1px] shadow-sm',
    className,
].filter((styles) => !!styles.length).join(' ')

const getLoaderColor = (variantKey: 'solid' | 'plain' | 'outlined', color: ButtonColor) => {
    if (variantKey === 'solid') {
        return 'white'
    } else if (variantKey === 'outlined') {
        return {
            blue: '#005EFF',
            red: '#EF4444',
            black: '#E5E7EB',
            slate: '#E5E7EB',
        }[color]
    } else {
        return '#E5E7EB'
    }
}

export default function Button({
    text,
    loading = false,
    outlined,
    plain,
    disabled,
    small,
    color = 'blue',
    className = '',
    children,
    type = 'button',
    ...rest
}: Props) {
    const activeKey = disabled || loading ? 'disabled' : 'enabled'
    const variantKey = plain ? 'plain' : outlined ? 'outlined' : 'solid'
    const sizeKey = small ? 'small' : 'default'
    const styles = getStyles(variantKey, activeKey, sizeKey, color, className)
    const loaderColor = getLoaderColor(variantKey, color)

    return (
        <button
            {...rest}
            className={styles}
            disabled={loading || disabled}
            type={type}
        >
            {loading && <SpinningLoader color={loaderColor} />}
            {children}
            {text}
        </button>
    )
}
