export const ROUTES = {
    //public routes
    Signup: '/signup',
    Login: '/login',
    EmailLogin: '/email-login',
    EmailLoginLink: '/email-login-link',
    SignupEmailConfirmation: '/signup-email-confirmation',
    ForgotPassword: '/forgot-password',
    ForgotPasswordEmailConfirmation: '/forgot-password-email-confirmation',
    ResetPassword: '/reset-password',
    Invitation: '/invitation',
    MemberInvitation: '/member_invitation',
    ContributorInvitation: '/contributor_invitation',
    AccountActivation: '/account_activation',
    // private routes
    Onboarding: '/onboarding',
    RequestFulfillment: '/request-fulfillment',
    Home: '/home',
    Companies: {
        Companies: '/companies',
        Create: 'create',
        Company: {
            Company: ':companyId',
            Conversations: {
                Conversations: '/conversations',
                Conversation: ':conversationId',
                Library: 'library',
                Ask: 'ask',
            },
            Settings: {
                Settings: '/settings',
                Company: 'company',
                Connectors: 'connectors',
                Tools: 'tools',
                Members: 'members',
            }
        },
    },
}
