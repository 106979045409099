import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils/Utils"
import { ENV } from "config/index"
import { baseQueryWithReauth } from "features/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/billing`})

export const billingsAPI = createApi({
    reducerPath: "billingAPI",
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    endpoints: (builder) => ({
        createBillingAccount: builder.query<{redirect_url: string}, void>({
            query: () => ({url: '/new_account_subscription'}),
            transformErrorResponse,
        }),
    }),
})

export const {useLazyCreateBillingAccountQuery} = billingsAPI
