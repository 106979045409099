import { Middleware } from "@reduxjs/toolkit"
import { clientTrackingActionType, trackedActionTypes } from "./constants"
import { updateSession } from "./trackingSlice"
import { getSessionIdsFromSession, getSessionNameFromRequestType, traverseAndUpdateSession } from "./utils"
import mixpanel from "mixpanel-browser"
import { Session, SessionName, TrackedRequestActionType } from "./types"

const trackingMiddleware: Middleware = ({dispatch, getState}) => (next) => (action) => {
    if (trackedActionTypes.includes(action.type)) {
        const {tracking: session} = getState() as {tracking: Session | null}
        const sessionName = action.type === clientTrackingActionType
            ? SessionName.APOKTO
            : getSessionNameFromRequestType(action.type as TrackedRequestActionType)

        const updatedSession = traverseAndUpdateSession(session, sessionName) // had side effect: expired sessions are sent to mixpanel

        dispatch(updateSession(updatedSession))

        if (action.type === clientTrackingActionType) {
            mixpanel.track(action.payload.event, {
                ...action.payload.props,
                ...getSessionIdsFromSession(updatedSession, sessionName),
            })
        }
    }

    return next(action)
}

export default trackingMiddleware
