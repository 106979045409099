import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Industry } from "features/companies/types"
import { RootState } from "redux/store"

const industries = createSlice({
    name: "industries",
    initialState: {
        industries: [] as Industry[],
    },
    reducers: {
        setIndustries: (
            state,
            action: PayloadAction<Industry[]>,
        ) => {
            state.industries = action.payload
        },
    },
})

export const {setIndustries} = industries.actions
export const industriesState = (state: RootState) => state.industries
export default industries.reducer
