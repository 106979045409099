import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils/Utils"
import {
    UpdateCompanyResponse,
    UpdateCompanyInput,
    Company,
    GetCompanyToolsResponse,
    Industry,
    GetIndustriesResponse,
    CreateCompanyInput,
    CreateCompanyResponse,
    CompanyIndexItem,
    CompanyTool,
    CompanyState,
} from "./types"
import { ENV } from "config"
import { UserInvitations } from "@apokto/feature-alexiares-api"
import { createAlexiaresApi } from "@apokto/feature-alexiares-client"
import { receiveTokens } from "features/authentication/authenticationSlice"
import { baseQueryWithReauth } from "features/authentication/utils/query"
import { getTokens } from "features/authentication/utils/storage"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/companies`})

export const companiesAPI = createApi({
    reducerPath: 'companiesAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['Companies', 'Tools', 'Events', 'Industries'],
    endpoints: (builder) => ({
        getCompany: builder.query<Company, string>({
            query: (id) => ({url: `/${id}`}),
            providesTags: (result, error, id) => [{type: 'Companies', id}],
            transformErrorResponse,
        }),
        getTools: builder.query<CompanyTool[], void>({
            query: () => ({url: '/tools'}),
            providesTags: () => [{type: 'Tools'}],
            transformErrorResponse,
            transformResponse: ({tools}: GetCompanyToolsResponse) => tools,
        }),
        getIndustries: builder.query<Industry[], void>({
            query: () => ({url: '/industries'}),
            providesTags: () => [{type: 'Industries'}],
            transformErrorResponse,
            transformResponse: ({industries}: GetIndustriesResponse) => industries
        }),
        updateCompany: builder.mutation<UpdateCompanyResponse, UpdateCompanyInput>({
            query: (body) => ({
                url: `/${body.id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (response, error, {id}) => [
                {type: 'Companies', id},
                {type: 'Companies', id: 'Accounts'},
            ],
            transformErrorResponse,
        }),
        createCompany: builder.mutation<CreateCompanyResponse, CreateCompanyInput>({
            query: (company) => ({
                method: 'POST',
                body: {
                    ...company,
                    presentation_config: {
                        // TODO fix this
                        // Backend default value is wrong for this field
                        avatar_bg_color: 0,
                    },
                },
            }),
            transformErrorResponse,
        }),
        getActiveCompanies: builder.query<CompanyIndexItem[], string | null>({
            query: (accountId) =>
                ({url: `?include_connection_states=true${accountId ? `&account_id=${accountId}` : ''}`}),
            providesTags: () => [{type: 'Companies', id: 'Accounts'}],
            transformErrorResponse,
            transformResponse: (response: {items: CompanyIndexItem[]}) =>
                response.items.filter(({state}) => state === CompanyState.Activated)
        }),
        deleteCompany: builder.mutation<void, string>({
            query: (id) => ({
                url: `/${id}?archive=false`,
                method: 'DELETE',
            }),
            invalidatesTags: (response, error, id) => [
                {type: 'Companies', id},
                {type: 'Companies', id: 'Accounts'},
            ],
            transformErrorResponse,
        }),
        archiveCompany: builder.mutation<Company, string>({
            query: (id) => ({
                url: `/${id}?archive=true`,
                method: 'DELETE',
            }),
            invalidatesTags: (response, error, id) => [
                {type: 'Companies', id},
                {type: 'Companies', id: 'Accounts'},
            ],
            transformErrorResponse,
        }),
        getInvitations: builder.query<UserInvitations, void>({
            queryFn: async (_, {dispatch}) => {
                const alexiaresApi = createAlexiaresApi(ENV.ALEXIARES_HOST_URL);
                const {accessToken, refreshToken} = await getTokens()
                try {
                    if (!accessToken) {
                        throw new Error('Invalid token')
                    }

                    let invitations_response = await alexiaresApi.fetchInvitationsSentToUser((builder) => {
                        builder.bearerToken(accessToken)
                    })

                    if (invitations_response.status === 401 && refreshToken) {
                        const token_response = await alexiaresApi.refreshToken((builder) => {
                            builder.refreshToken(refreshToken)
                        })

                        const token_data = token_response.data
                        if (token_data) {
                            dispatch(receiveTokens(token_data))
                            invitations_response = await alexiaresApi.fetchInvitationsSentToUser((builder) => {
                                builder.bearerToken(token_data.access_token)
                            })
                        }
                    }

                    if (invitations_response.data) {
                        return { data: invitations_response.data }
                    } else {
                        return {
                            error: transformErrorResponse(
                                {status: 'CUSTOM_ERROR', error: 'An error occurred while fetching'},
                                undefined,
                                null
                            ) as FetchBaseQueryError,
                        }
                    }
                } catch (error) {
                    return {
                        error: transformErrorResponse(
                            {status: 'CUSTOM_ERROR', error: 'An error occured while building the query'},
                            undefined,
                            null
                        ) as FetchBaseQueryError,
                    }
                }
            },
            providesTags: () => [{type: 'Companies', id: 'All Invitations'}],
        }),
        acceptCompanyContributorInvitation: builder.mutation<void, {invitationId: string, accept: boolean}>({
            query: ({invitationId, ...body}) => ({
                url: `/invitations/${invitationId}/response`,
                method: 'POST',
                body,
            }),
            transformErrorResponse,
        }),
    }),
})

export const {
    useGetCompanyQuery,
    useLazyGetCompanyQuery,
    useGetToolsQuery,
    useLazyGetToolsQuery,
    useGetIndustriesQuery,
    useLazyGetIndustriesQuery,
    useUpdateCompanyMutation,
    useCreateCompanyMutation,
    useGetActiveCompaniesQuery,
    useDeleteCompanyMutation,
    useArchiveCompanyMutation,
    useGetInvitationsQuery,
    useAcceptCompanyContributorInvitationMutation,
} = companiesAPI
