import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "redux/store"

const recentCompaniesSlice = createSlice({
    name: "recentCompanies",
    initialState: {} as Record<string, string[]>,
    reducers: {
        addRecentCompany(state, action: PayloadAction<{accountId: string, companyId: string}>) {
            const {accountId, companyId} = action.payload
            const recentCompanies = state[accountId]

            if (recentCompanies) {
                const [mostRecentStoredCompanyId] = recentCompanies
                const updatedRecentCompanies = mostRecentStoredCompanyId !== companyId
                    ? [companyId, ...recentCompanies.filter((recentCompanyId) => companyId !== recentCompanyId).slice(0, 4)]
                    : recentCompanies
                
                return {...state, [accountId]: updatedRecentCompanies}
            }

            return {...state, [accountId]: [companyId]}
        },
        removeRecentCompany(state, action: PayloadAction<{accountId: string, companyId: string}>) {
            const {accountId, companyId} = action.payload
            const recentCompanies = state[accountId]

            if (recentCompanies) {
                return {
                    ...state,
                    [accountId]: recentCompanies.filter((recentCompanyId) => recentCompanyId !== companyId),
                }
            }

            return {...state, [accountId]: []}
        }
    },
})

export default recentCompaniesSlice.reducer
export const {addRecentCompany, removeRecentCompany} = recentCompaniesSlice.actions
export const recentCompaniesState = (state: RootState) => state.recentCompanies