import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MixpanelEvent, Session } from './types'

const trackingSlice = createSlice({
    name: 'tracking',
    initialState: null as Session | null,
    reducers: {
        updateSession(state, {payload}: PayloadAction<Session>) {
            return payload
        },
        trackEvent(state, payload: PayloadAction<{event: MixpanelEvent, props: Record<any, any>}>) {
            // do nothing (used for middleware intercept)
        },
    },
})

export const {updateSession, trackEvent} = trackingSlice.actions
export default trackingSlice.reducer
