export enum MixpanelEvent {
    PAGE_VIEWED = 'page_viewed',
    SESSION_INITIATED = 'session_initiated',
    SESSION_COMPLETED = 'session_completed',
}

export enum SessionName {
    APOKTO = 'apokto',
    ZOE = 'zoe',
    DASHBOARDS = 'dashboards',
}

export interface Session {
    id: string
    name: SessionName
    created_at: number
    updated_at: number
    sessions: Session[]
}

// strings mapped to those generated by RTK's createApi method, one mutation action type per api, one api per feature
// mutations are responsible for POST, PATCH and DELETE requests
export enum TrackedRequestActionType {
    ZOE_MUTATION_ACTION = 'zoeAPI/executeMutation/pending'
}
