import { Alert, KPIType } from "./types"

export const getFormattedThresholdValueFromAlert = (alert: Alert) => {
    switch (alert.kpi.type) {
        case KPIType.Days:
            return `${alert.threshold} Days`
        case KPIType.Monetary:
            return `$${Intl.NumberFormat("en-US", {
                maximumFractionDigits: 10,
            }).format(Number(alert.threshold))}`
        case KPIType.Numeric:
            return `${alert.threshold}`
        case KPIType.Percentage:
            return `${alert.threshold}%`
        case KPIType.Months:
            return `${alert.threshold} Months`
        default:
            return null
    }
}

export const sortAlertsById = (alerts: Alert[]) =>
    alerts.sort((a, b) => {
        if (a.id < b.id) {
            return 1
        }
        if (a.id > b.id) {
            return -1
        }
        return 0
    })