import { currentUser } from "features/authentication/authenticationSlice"
import { useLazyGetIndustriesQuery, useLazyGetToolsQuery } from "features/companies/companiesAPI"
import { setConnections } from "features/connections/connectionsSlice"
import { useLazyGetAllConnectionsQuery } from "features/financials/financialsAPI"
import { setIndustries } from "features/industries/industriesSlice"
import { setTools } from "features/tools/toolsSlice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function LoadAppData() {
    const dispatch = useDispatch()
    const [getIndustries] = useLazyGetIndustriesQuery()
    const [getTools] = useLazyGetToolsQuery()
    const [getConnections] = useLazyGetAllConnectionsQuery()
    const user = useSelector(currentUser)

    useEffect(() => {
        if (user) {
            getIndustries().unwrap().then((industries) => dispatch(setIndustries(industries)))
            getTools().unwrap().then((tools) => dispatch(setTools(tools)))
            getConnections().unwrap().then((connections) => dispatch(setConnections(connections)))
        }
    }, [user])

    return null
}
