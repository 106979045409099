export interface Account {
    id: string
    name: string
    type: number
    members: Member[]
    member_invitations: MemberInvitation[]
    invited_owner_email: string
    billing_config: BillingConfig
    config: Config
    state: number
    activated_at: Date
    paying_customer?: PayingCustomer
    website: string
    about: string
    industry: string
    linkedin: string
    specialties: string[]
}

export interface BillingConfig {
    price: Price
    cycle: number
}

export interface Price {
    amount: string
    currency: string
}

export interface Config {
    active_company_limit: number
    active_member_limit: number
    contributors_per_company: number
    apokto_features: number
    apokto_integrations: number
}

export interface Member {
    account_role: AccountRole
    account_state: AccountMemberState
    user: User
}

export interface User {
    id: string
    first_name: string
    last_name: string
    email: string
    confirmed_at: Date
}

export interface PayingCustomer {
    id: string
    payment_method_setup_id: string
}

export interface MemberInvitation {
    account_id: string
    invitee_email: string
    user_id: string
    expires_at: Date
    initial_role: number
    invitation_state: number
}

// Invitation
export enum AccountRole {
    OWNER = 1,
    ADMIN,
    USER,
}

// Account role to string map
export const AccountRoleToStringMap = {
    [AccountRole.OWNER]: "Owner",
    [AccountRole.ADMIN]: "Admin",
    [AccountRole.USER]: "Member",
}

// Account role enum to string map
export const AccountRoleEnumToStringMap = {
    Owner: AccountRole.OWNER,
    Admin: AccountRole.ADMIN,
    Member: AccountRole.USER,
}

export const enum AccountMemberState {
    ACTIVE = 1,
    REMOVED,
}
export const AccountMemberStateToStringMap = {
    [AccountMemberState.ACTIVE]: "Active",
    [AccountMemberState.REMOVED]: "Removed",
}

export interface SendMemberInvitationInput {
    email: string
    initial_role: AccountRole
    account_id: string
}
export const enum MemberInvitationState {
    SENT = 0,
    ACCEPTED = 1,
    DECLINED = 2,
    RESCINDED = 3,
    RESENT = 4,
    EXPIRED = 5, // <-- a synthetic state evaluated by the services based upon expires_at
}
// number to member invitation state map
export const MemberInvitationStateToStringMap = {
    [MemberInvitationState.SENT]: "Sent",
    [MemberInvitationState.ACCEPTED]: "Accepted",
    [MemberInvitationState.DECLINED]: "Declined",
    [MemberInvitationState.RESCINDED]: "Rescinded",
    [MemberInvitationState.RESENT]: "Resent",
    [MemberInvitationState.EXPIRED]: "Expired",
}
export interface MemberInvitationResponse {
    account_id: string
    invitee_email: string
    user_id?: string
    expires_at: Date
    initial_role: AccountRole
    invitation_state: MemberInvitationState
}

// get accounts

export interface GetLoggedInUserAccountsResponse {
    user_id: string
    accounts: GetLoggedInUserAccountsResponseAccount[]
}

export interface GetLoggedInUserAccountsResponseAccount {
    id: string
    name: string
    role: AccountRole
    member_state: AccountMemberState
    state: number
}

// Resciend invite
export interface RescindInvitationInput {
    invitee_email: string
    account_id: string
}

// update member
export interface UpdateMemberInput {
    account_role?: number
    member_state?: number
    accountId: string
    userId: string
}

// update account
export interface UpdateAccountInput {
    accountId: string
    name?: string
    website?: string
    about?: string
    industry?: string
    specialties_to_add?: string[]
    specialties_to_remove?: string[]
    linkedin?: string
}

export interface AcceptAccountInvitationProps {
    accept: boolean
    accountId: string
    invitee_email: string
}