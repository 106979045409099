import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { CompanySettingsFeature } from "./types";

const companySettings = createSlice({
    name: "companySettings",
    initialState: {} as Record<string, CompanySettingsFeature | null>,
    reducers: {
        setActiveTab: (state, {payload: {companyId, tab}}: PayloadAction<{companyId: string, tab: CompanySettingsFeature}>) => {
            return {
                ...state,
                [companyId]: tab,
            }
        },
    },
})

export const {setActiveTab} = companySettings.actions
export const companySettingsState = (state: RootState) => state.companySettings
export default companySettings.reducer
