import { Suspense, lazy, useEffect } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { RouteType, renderRoute } from "./utils"
import { ROUTES } from "router/constants"
import { useDispatch } from "react-redux"
import { AppDispatch } from "redux/store"
import { checkRefreshTokenValidity } from "features/authentication/authenticationSlice"
import Intercom from "components/Intercom/Intercom"
import FullPageLoading from "components/_shared/Loading/FullPageLoading"

const publicRoutes: RouteType[] = [
    {
        path: ROUTES.Login,
        element: lazy(() => import("pages/login/Login")),
    },
    {
        path: ROUTES.EmailLogin,
        element: lazy(() => import("pages/login/EmailLogin")),
    },
    {
        path: ROUTES.EmailLoginLink,
        element: lazy(() => import("pages/login/EmailLoginLink")),
    },
    {
        path: ROUTES.Signup,
        element: lazy(() => import("pages/signup/Signup")),
    },
    {
        path: ROUTES.SignupEmailConfirmation,
        element: lazy(() => import("pages/signup-email-confirmation/SignupEmailConfirmation")),
    },
    {
        path: ROUTES.ForgotPassword,
        element: lazy(() => import("pages/forgot-password/ForgotPassword")),
    },
    {
        path: ROUTES.ForgotPasswordEmailConfirmation,
        element: lazy(() => import("pages/forgot-password-email-confirmation/ForgotPasswordEmailConfirmation")),
    },
    {
        path: ROUTES.ResetPassword,
        element: lazy(() => import("pages/reset-password/ResetPassword")),
    },
    {
        path: ROUTES.AccountActivation,
        element: lazy(
            () => import("pages/account-activation/AccountActivation")
        ),
    },
    {
        path: ROUTES.MemberInvitation,
        element: lazy(
            () => import("pages/member-invitation/MemberInvitation")
        ),
    },
    {
        path: ROUTES.ContributorInvitation,
        element: lazy(
            () =>
                import("pages/contributor-invitation/ContributorInvitation")
        ),
    },
]

const privateRoutes: RouteType[] = [
    {
        path: '/oauth-complete',
        element: lazy(() => import("pages/oauth-complete/OAuthComplete")),
    },
    {
        path: ROUTES.Onboarding,
        element: lazy(() => import("pages/onboarding/Onboarding")),
    },
    {
        path: ROUTES.RequestFulfillment,
        element: lazy(
            () => import("pages/request-fulfillment/RequestFulfillment")
        ),
    },
    {
        path: ROUTES.Home,
        element: lazy(() => import("pages/home/Home")),
    },
    {
        path: `${ROUTES.Companies.Companies}/${ROUTES.Companies.Create}`,
        element: lazy(() => import("pages/companies/create/Create")),
    },
    {
        path: `${ROUTES.Companies.Companies}/${ROUTES.Companies.Company.Company}`,
        element: lazy(() => import('pages/companies/company/Company')),
        outletElements: [
            {
                path: `${ROUTES.Companies.Companies}/${ROUTES.Companies.Company.Company}/${ROUTES.Companies.Company.Conversations.Conversations}`,
                element: lazy(() => import('pages/companies/company/conversations/Conversations')),
                outletElements: [
                    {
                        path: ROUTES.Companies.Company.Conversations.Library,
                        element: lazy(() => import('pages/companies/company/conversations/library/Library')),
                    },
                    {
                        path: ROUTES.Companies.Company.Conversations.Ask,
                        element: lazy(() => import('pages/companies/company/conversations/ask/Ask')),
                    },
                    {
                        path: ROUTES.Companies.Company.Conversations.Conversation,
                        element: lazy(() => import('pages/companies/company/conversations/conversation/Conversation')),
                    },
                ]
            },
            {
                path: `${ROUTES.Companies.Companies}/${ROUTES.Companies.Company.Company}/${ROUTES.Companies.Company.Settings.Settings}`,
                element: lazy(() => import('pages/companies/company/settings/Settings')),
                outletElements: [
                    {
                        path: ROUTES.Companies.Company.Settings.Company,
                        element: lazy(() => import('pages/companies/company/settings/company/Company')),
                    },
                    {
                        path: ROUTES.Companies.Company.Settings.Connectors,
                        element: lazy(() => import('pages/companies/company/settings/connectors/Connectors')),
                    },
                    {
                        path: ROUTES.Companies.Company.Settings.Tools,
                        element: lazy(() => import('pages/companies/company/settings/tools/Tools')),
                    },
                    {
                        path: ROUTES.Companies.Company.Settings.Members,
                        element: lazy(() => import('pages/companies/company/settings/members/Members')),
                    },
                ]
            },
        ],
    },
]

const NotFound404 = lazy(() => import("pages/not-found/NotFound"))

export default function Router() {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(checkRefreshTokenValidity())
    }, [])

    return (
        <BrowserRouter>
            <Intercom />
            <Routes>
                <Route
                    index
                    element={
                        <Navigate
                            to={ROUTES.Home}
                        />
                    }
                />

                {publicRoutes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <Suspense
                                fallback={
                                    <FullPageLoading />
                                }
                            >
                                <route.element />
                            </Suspense>
                        }
                    />
                ))}

                {privateRoutes.map((route, index) => renderRoute(route, index))}

                <Route path="*" element={<NotFound404 />} />
            </Routes>
        </BrowserRouter>
    )
}
