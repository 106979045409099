import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { NavFeature, NavTab } from "./types"
import { v4 as uuid } from 'uuid';

interface TabState {
    tabs: NavTab[],
    activeTab: NavTab | null
}

const nav = createSlice({
    name: "nav",
    initialState: {} as Record<string, TabState>,
    reducers: {
        pushTab: (state, {payload: {feature, companyId}}: PayloadAction<{companyId: string, feature: NavFeature}>) => {
            const newTab = {id: uuid(), feature}

            return {
                ...state,
                [companyId]: {
                    activeTab: newTab,
                    tabs: [...(state[companyId]?.tabs || []), newTab]
                },
            }
        },
        removeTab: (state, {payload: {companyId, tabId}}: PayloadAction<{companyId: string, tabId: string}>) => {
            const filteredTabs = (state[companyId]?.tabs || []).filter(({id}) => id !== tabId)

            return {
                ...state,
                [companyId]: {
                    activeTab: filteredTabs[filteredTabs.length - 1],
                    tabs: filteredTabs,
                },
            }
        },
        setActiveTab: (state, {payload: {companyId, tabId}}: PayloadAction<{companyId: string, tabId: string}>) => {
            const foundTab = state[companyId].tabs.find(({id}) => id === tabId) as NavTab

            return {
                ...state,
                [companyId]: {
                    ...state[companyId],
                    activeTab: foundTab,
                },
            }
        },
    },
})

export const {pushTab, removeTab, setActiveTab} = nav.actions
export const navState = (state: RootState) => state.nav
export default nav.reducer
