interface Props {
    color?: string
    size?: number
}

export default function SpinningLoader({color = 'white', size = 16}: Props) {
    return (
        <div
            className="overflow-hidden"
            style={{height: `${size}px`, width: `${size}px`}}
        >
            <span
                className={"animate-spin inline-block border-[3px] border-current border-t-transparent rounded-full"}
                style={{color, height: `${size}px`, width: `${size}px`}}
            />
        </div>
    )
}