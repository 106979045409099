import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { CompanyIndexItem } from "./types"

interface CompaniesState {
    companySettingsModal: {
        open: boolean
        company: CompanyIndexItem | null
    }
    isSidebarVisible: boolean
}

const initialState: CompaniesState = {
    companySettingsModal: {
        open: false,
        company: null,
    },
    isSidebarVisible: false,
}

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        openSettingsModal: (state, action: PayloadAction<CompanyIndexItem>) => ({
            ...state,
            companySettingsModal: {
                open: true,
                company: action.payload,
            },
        }),
        closeSettingsModal: (state) => ({
            ...state,
            companySettingsModal: {
                open: false,
                company: null,
            },
        }),
        setIsSidebarVisible: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isSidebarVisible: action.payload,
        }),
    },
})

export default companiesSlice.reducer
export const {closeSettingsModal, openSettingsModal, setIsSidebarVisible} = companiesSlice.actions
export const companiesState = (state: RootState) => state.companies
