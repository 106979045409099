import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Member } from "features/accounts/types"
import { CompanyIndexItem } from "features/companies/types"
import { RootState } from "redux/store"

interface AddMemberModalProps {
    accountId: string
    member?: Member | null
}

interface ArchiveCompanyProps {
    company: CompanyIndexItem
}

interface DeleteCompanyProps {
    company: CompanyIndexItem
}

interface ConfirmStripeRedirectModal {

}

interface AcknowledgeBillingCancelModal {

}

const home = createSlice({
    name: "home",
    initialState: {
        addMemberModal: null as AddMemberModalProps | null,
        archiveCompanyModal: null as ArchiveCompanyProps | null,
        deleteCompanyModal: null as DeleteCompanyProps | null,
        confirmStripeRedirectModal: null as ConfirmStripeRedirectModal | null,
        acknowledgeBillingCancelModal: null as AcknowledgeBillingCancelModal | null,
    },
    reducers: {
        setAddMemberModal: (state, action: PayloadAction<null | AddMemberModalProps>) => ({
            ...state,
            addMemberModal: action.payload,
        }),
        setArchiveCompanyModal: (state, action: PayloadAction<null | ArchiveCompanyProps>) => ({
            ...state,
            archiveCompanyModal: action.payload,
        }),
        setDeleteCompanyModal: (state, action: PayloadAction<null | DeleteCompanyProps>) => ({
            ...state,
            deleteCompanyModal: action.payload,
        }),
        setConfirmStripeRedirectModal: (state, action: PayloadAction<null | ConfirmStripeRedirectModal >) => ({
            ...state,
            confirmStripeRedirectModal: action.payload,
        }),
        setAcknowledgeBillingCancelModal: (state, action: PayloadAction<null | AcknowledgeBillingCancelModal >) => ({
            ...state,
            acknowledgeBillingCancelModal: action.payload,
        }),
    },
})

export const {
    setAddMemberModal,
    setArchiveCompanyModal,
    setDeleteCompanyModal,
    setConfirmStripeRedirectModal,
    setAcknowledgeBillingCancelModal,
} = home.actions
export const homeState = (state: RootState) => state.home
export default home.reducer
