import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Connection } from "features/financials/types"
import { RootState } from "redux/store"

const connections = createSlice({
    name: 'connections',
    initialState: {
        connections: [] as Connection[],
    },
    reducers: {
        setConnections: (
            state,
            action: PayloadAction<Connection[]>,
        ) => {
            state.connections = action.payload.map((connection) => ({
                ...connection,
                // TODO remove hack (handle in API)
                is_enabled: ['SHOPIFY','SQUARE', 'PLAID', 'STRIPE'].includes(connection.rutter_id) ? false : connection.is_enabled
            }))
        },
    },
})

export const {setConnections} = connections.actions
export const connectionsState = (state: RootState) => state.connections
export default connections.reducer
