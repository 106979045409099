export const saveAccountSelectionForUser = (userId: string, accountId: string) => {
    localStorage.setItem(`accountSelection_${userId}`, accountId)
}

export const getAccountSelectionForUser = (userId: string) => {
    return localStorage.getItem(`accountSelection_${userId}`)
}

export const removeAccountSelectionForUser = (userId: string) => {
    localStorage.removeItem(`accountSelection_${userId}`)
}
