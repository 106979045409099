import { Suspense } from "react"
import { Route } from "react-router-dom"
import ProtectedRoute from "./ProtectedRoute"
import { ClipLoader } from "react-spinners"

export type RouteType = {
    path: string
    element: React.LazyExoticComponent<any>
    outletElements?: RouteType[]
}

// recursive function to render N levels of childrens
export const renderRoute = (
    route: RouteType,
    index: number,
    fromOutlet?: boolean
): any =>
    route.outletElements ? (
        <Route
            key={index}
            path={route.path}
            element={
                fromOutlet ? (
                    <Suspense
                        fallback={
                            <div className="flex w-full h-full items-center justify-center">
                                <ClipLoader color="#005EFF" loading size={45} />
                            </div>
                        }
                    >
                        <route.element />
                    </Suspense>
                ) : (
                    <ProtectedRoute>
                        <Suspense
                            fallback={
                                <div className="flex w-full h-full items-center justify-center">
                                    <ClipLoader
                                        color="#005EFF"
                                        loading
                                        size={45}
                                    />
                                </div>
                            }
                        >
                            <route.element />
                        </Suspense>
                    </ProtectedRoute>
                )
            }
        >
            {route.outletElements.map((outletElement, index: number) =>
                renderRoute(outletElement, index, true)
            )}
        </Route>
    ) : (
        <Route
            key={route.path}
            path={route.path}
            element={
                fromOutlet ? (
                    <Suspense
                        fallback={
                            <div className="flex w-full h-full items-center justify-center">
                                <ClipLoader color="#005EFF" loading size={45} />
                            </div>
                        }
                    >
                        <route.element />
                    </Suspense>
                ) : (
                    <ProtectedRoute>
                        <Suspense
                            fallback={
                                <div className="flex w-full h-full items-center justify-center">
                                    <ClipLoader
                                        color="#005EFF"
                                        loading
                                        size={45}
                                    />
                                </div>
                            }
                        >
                            <route.element />
                        </Suspense>
                    </ProtectedRoute>
                )
            }
        />
    )
