import Button from "components/common/Button"
import { useLazyCreateBillingAccountQuery } from "features/billing/billingsAPI"
import toast from "react-hot-toast"
import { ServerError } from "types/errors"

interface Props {
    onClose: () => void
}

export default function ConfirmStripeRedirectModal({onClose}: Props) {
    const [createBillingAccount] = useLazyCreateBillingAccountQuery()

    const handleConfirm = () => {
        createBillingAccount().unwrap()
        .then(({redirect_url}) => {
            window.open(redirect_url)
            onClose()
        })
        .catch(error => {
            if (error.data) {
                error.data.forEach(({msg}: ServerError) => {
                    toast.error(msg);
                });
            } else {
                console.error(error);
            }
        })
    }

    return (
        <div className="flex flex-col gap-5 items-center pb-10 px-10">
            <div className="flex flex-col items-center gap-[10px]">
                <div className="text-[#1F2937] text-2xl font-semibold tracking-[0.12px]">
                    Navigate to Stripe
                </div>
                <div className="text-[#6B7280] text-[16px] font-normal leading-[24px] tracking-[0.08px]">
                Are you sure you want to navigate to Stripe to set up billing?
                </div>
            </div>
            <div className="flex gap-4">
                <Button
                    small
                    color="black"
                    plain
                    onClick={handleConfirm}
                    text="Confirm"
                />
                <Button
                    small
                    text="Cancel"
                    onClick={onClose}
                />
            </div>
        </div>
    )
}