import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils/Utils"
import {
    GetConnectionsResponseWithoutGuaranteedState,
    SyncConnectionResponse,
    GetReportsRequest,
    UpdateFinancialConnectionResponse,
    IncomeDashboardModel,
    ExpenseDashboardModel,
    IncomeStatement,
    CashflowStatement,
    BalanceSheet,
    ValuationDashboard,
    GetConnectionsResponse,
    Connection,
    FinancialConnectionId,
    PatchValuationRequestBody,
} from "./types"
import { ENV } from "config"
import { UpdateCompanyResponse } from "features/companies/types"
import { getAllConnections, getConnectionsUserHasAccessTo, getDatesQueryString } from "./utils"
import { baseQueryWithReauth } from "features/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: ENV.CIRCE_HOST_URL})

export const financialsAPI = createApi({
    reducerPath: 'financialsAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['Financials', 'Connections'],
    endpoints: (builder) => ({
        rutterTokenExchange: builder.mutation<void, {public_token: string, company_id: string}>({
            query: (body) => ({
                url: `/rutter/token_exchange`,
                method: 'POST',
                body,
            }),
            transformErrorResponse,
        }),
        getAllConnections: builder.query<Connection[], void>({
            query: () => ({url: '/connections'}),
            transformErrorResponse,
            transformResponse: getAllConnections,
        }),
        getConnections: builder.query<GetConnectionsResponse, string>({
            query: (companyId) => ({url: `/connections?company_id=${companyId}`}),
            transformErrorResponse,
            transformResponse: getConnectionsUserHasAccessTo,
            providesTags: [{type: 'Connections', id: 'LIST'}],
        }),
        createPendingFinancialConnection: builder.mutation<UpdateCompanyResponse, {company_id: string, connection_id: FinancialConnectionId}>({
            query: ({company_id, ...body}) => ({
                url: `/connections/pending?company_id=${company_id}`,
                method: 'POST',
                body,
            }),
            transformErrorResponse,
            invalidatesTags: [{type: 'Connections', id: 'LIST'}],
        }),
        updatePendingFinancialConnection: builder.mutation<UpdateFinancialConnectionResponse, {company_id: string, connection_id: FinancialConnectionId, connector_email: string}>({
            query: ({company_id, ...body}) => ({
                url: `/connections/pending?company_id=${company_id}`,
                method: 'PATCH',
                body,
            }),
            transformErrorResponse,
            invalidatesTags: [{type: 'Connections', id: 'LIST'}],
        }),
        disconnectFinancialConnection: builder.mutation<GetConnectionsResponseWithoutGuaranteedState, {company_id: string, connection_id: FinancialConnectionId}>({
            query: ({connection_id, company_id}) => ({
                url: `/connections/${connection_id}?company_id=${company_id}`,
                method: 'DELETE',
            }),
            transformErrorResponse,
            invalidatesTags: [{type: 'Connections', id: 'LIST'}],
        }),
        syncConnection: builder.mutation<SyncConnectionResponse, {company_id: string, connection_id: FinancialConnectionId}>({
            query: ({connection_id, ...body}) => ({
                url: `/connections/${connection_id}/sync`,
                method: 'POST',
                body,
            }),
            transformErrorResponse,
            invalidatesTags: (result, error, {company_id, connection_id}) =>
                [{type: 'Financials', id: `ConnectionSyncStatus_${company_id}_${connection_id}`}],
        }),
        getConnectionSyncStatus: builder.query<SyncConnectionResponse, {company_id: string, connection_id: FinancialConnectionId}>({
            query: ({connection_id, company_id}) => ({
                url: `/connections/${connection_id}/sync?company_id=${company_id}`,
                method: 'GET',
            }),
            transformErrorResponse,
            providesTags: (result, error, {company_id, connection_id}) =>
                [{type: 'Financials', id: `ConnectionSyncStatus_${company_id}_${connection_id}`}],
        }),
        getIncomeReports: builder.query<IncomeDashboardModel, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                    url: `/reports/dashboards/income?company_id=${company_id}${getDatesQueryString(dates)}`,
            }),
        }),
        getExpenseReports: builder.query<ExpenseDashboardModel, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                    url: `/reports/dashboards/expense?company_id=${company_id}${getDatesQueryString(dates)}`,
            }),
        }),
        getIncomeStatement: builder.query<IncomeStatement, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                url: `/reports/statements/income?company_id=${company_id}${getDatesQueryString(dates)}`,
            }),
        }),
        getCashflowStatement: builder.query<CashflowStatement, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                url: `/reports/statements/cashflow?company_id=${company_id}${getDatesQueryString(dates)}`,
            }),
        }),
        getBalanceSheet: builder.query<BalanceSheet, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                url: `/reports/statements/balancesheet?company_id=${company_id}${getDatesQueryString(dates)}`,
            })
        }),
        getValuation: builder.query<ValuationDashboard, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                url: `/reports/dashboards/valuation?company_id=${company_id}${getDatesQueryString(dates)}`,
            }),
        }),
        patchValuation: builder.mutation<ValuationDashboard, {companyId: string} & PatchValuationRequestBody>({
            query: ({companyId, ...body}) => ({
                url: `/reports/dashboards/valuation?company_id=${companyId}`,
                method: 'PATCH',
                body,
            }),
        }),
        getExcelReport: builder.query<any, GetReportsRequest>({
            query: ({company_id, ...dates}) => ({
                url: `/reports/io/xlsx?company_id=${company_id}${getDatesQueryString(dates)}`,
                responseHandler: async (response: any) =>
                    window.location.assign(window.URL.createObjectURL(await response.blob())),
                cache: 'no-cache',
            }),
        }),
        quickbooksAuth: builder.query<{ authUrl: string }, { companyId: string; isOnboarding: boolean }>({
            query: ({ companyId, isOnboarding }) => ({
              url: '/quickbooks/auth',
              params: { company_id: companyId, isOnboarding: isOnboarding.toString() },
            }),
          }),
          disconnectQuickbooks: builder.mutation<{ success: boolean; message: string }, string>({
            query: (companyId) => ({
              url: '/quickbooks/disconnect',
              method: 'POST',
              params: { company_id: companyId },
            }),
          }),
    }),
})

export const {
    useGetAllConnectionsQuery,
    useLazyGetAllConnectionsQuery,
    useGetConnectionsQuery,
    useLazyGetConnectionsQuery,
    useCreatePendingFinancialConnectionMutation,
    useUpdatePendingFinancialConnectionMutation,
    useSyncConnectionMutation,
    useGetConnectionSyncStatusQuery,
    useGetIncomeReportsQuery,
    useGetExpenseReportsQuery,
    useGetIncomeStatementQuery,
    useGetCashflowStatementQuery,
    useGetBalanceSheetQuery,
    useGetValuationQuery,
    useLazyGetExcelReportQuery,
    useRutterTokenExchangeMutation,
    useDisconnectFinancialConnectionMutation,
    usePatchValuationMutation,
    useQuickbooksAuthQuery,
    useDisconnectQuickbooksMutation,
} = financialsAPI
