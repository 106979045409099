import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { StaticDateRange } from "features/dashboards/types"

dayjs.extend(quarterOfYear)

export function getRangeForMonth(year: number, month: number) {
    const startDate = dayjs(new Date(year, month, 1)).add(1, "day")
    const endDate = startDate.add(1, "month").subtract(1, "day")
    return { startDate: startDate.toDate(), endDate: endDate.toDate() }
}

export function getRangeForQuarter(year: number, quarter: number) {
    const startDate = dayjs(new Date(year, (quarter - 1) * 3, 1)).add(1, "day")
    const endDate = startDate.add(3, "month").subtract(1, "day")
    return { startDate: startDate.toDate(), endDate: endDate.toDate() }
}

export function getRangeForYear(year: number) {
    const startDate = dayjs(new Date(year, 0, 1))
    const endDate = startDate.add(1, "year").subtract(1, "day")
    return { startDate: startDate.toDate(), endDate: endDate.toDate() }
}


export const currentYear = dayjs().year()
export const currentMonth = dayjs().month()
export const currentQuarter = dayjs().quarter()

export const definedRanges: {
    past: {
        key: StaticDateRange
        label: string
        startDate: Date
        endDate: Date
    }[]

    present: {
        key: StaticDateRange
        label: string
        startDate: Date
        endDate: Date
    }[]

    future: {
        key: StaticDateRange
        label: string
        startDate: Date
        endDate: Date
    }[]
} = {
    past: [
        {
            label: "Previous Month",
            key: "last_month",
            ...getRangeForMonth(currentYear, currentMonth - 1),
        },
        {
            label: "Previous Quarter",
            key: "last_quarter",
            ...getRangeForQuarter(currentYear, currentQuarter - 1),
        },
        {
            label: "Previous Year",
            key: "last_twelve_months",
            ...getRangeForYear(currentYear - 1),
        },
    ],
    present: [
        {
            label: "This Month",
            key: "this_month",
            ...getRangeForMonth(currentYear, currentMonth),
        },
        {
            label: "This Quarter",
            key: "this_quarter",
            ...getRangeForQuarter(currentYear, currentQuarter),
        },
        {
            label: "This Year",
            key: "this_year",
            ...getRangeForYear(currentYear),
        },
    ],
    future: [
        {
            label: "Next Month",
            key: "next_month",
            ...getRangeForMonth(currentYear, currentMonth + 1),
        },
        {
            label: "Next Quarter",
            key: "next_quarter",
            ...getRangeForQuarter(currentYear, currentQuarter + 1),
        },
        {
            label: "Next Year",
            key: "next_year",
            ...getRangeForYear(currentYear + 1),
        },
    ],
}
