import React, { useState } from "react"
import { ReactComponent as Close } from "assets/icons/Close.svg"
interface Props {
    title?: string
    children?: React.ReactNode
    unmountOnClose?: boolean
    visible?: boolean
    onClose: () => void
    showCloseButton?: boolean
    containerClassName?: string
    innerContainerClassName?: string
}

const Modal: React.FC<Props> = ({
    title,
    children,
    onClose,
    showCloseButton = true,
    containerClassName = "",
    innerContainerClassName = "",
}) => {
    return (
        <div
            className="bg-gray-900 bg-opacity-50 hs-overlay w-[100vw] h-[100vh] fixed top-0 left-0 z-[600] overflow-x-hidden overflow-y-auto"
        >
            <div
                className={
                    " mt-7 opacity-100 duration-500 ease-out transition-all sm:mx-auto flex items-center " +
                    containerClassName
                }
            >
                <div
                    className={
                        "w-full flex flex-col border shadow-sm rounded-xl -dark:bg-gray-800 bg-white " +
                        innerContainerClassName
                    }
                >
                    {showCloseButton && (
                        <div className="flex justify-between items-center pt-3 px-4 -dark:border-gray-700">
                            {title && (
                                <div className="text-[20px] font-bold tracking-[0.1px] text-center text-gray-800">{title}</div>
                            )}
                            {
                                <div className="w-full flex justify-end items-center">
                                    <button
                                        data-cy="closeModalButton"
                                        onClick={onClose}
                                        type="button"
                                        className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-slate-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm -dark:focus:ring-gray-700 -dark:focus:ring-offset-gray-800"
                                        data-hs-overlay="#hs-invite-members-modal"
                                    >
                                        <Close />
                                    </button>
                                </div>
                            }
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal
