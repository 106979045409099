import { authenticationState } from "features/authentication/authenticationSlice"
import { PropsWithChildren } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { ROUTES } from "router/constants"
import FullPageLoading from "components/_shared/Loading/FullPageLoading"

export default function ProtectedRoute({children}: PropsWithChildren) {
    const {currentUser, loading} = useSelector(authenticationState)

    if (loading) {
        return <FullPageLoading />
    }

    if (currentUser) {
        return children
    }

    return <Navigate to={ROUTES.Login} />
}
