import {
    AccountMemberState,
    AccountRole,
    GetLoggedInUserAccountsResponseAccount,
    Member,
} from "features/accounts/types"
import EmailInput from "./EmailInput"
import PersonCheck from "assets/svg/account-settings/person-check.svg"
import PersonBadge from "assets/svg/account-settings/person-badge.svg"
import Xlg from "assets/svg/account-settings/x-lg.svg"
import RoleToggle from "./RoleToggle"
import Button from "components/_shared/Button/Button"
import { toast } from "react-hot-toast"
import { useSendInviteMutation, useUpdateMemberMutation } from "features/accounts/accountsAPI"
import { APIError } from "types/errors"
import { useEffect, useState } from "react"
import { accountsState } from "features/accounts/accountsSlice"
import { useSelector } from "react-redux"

interface Props {
    accountId: string
    member?: Member | null
    onClose: () => void
}

export default function AddMemberModal({accountId, member, onClose}: Props) {
    const {currentUserAccounts} = useSelector(accountsState)
    const currentAccount = currentUserAccounts.find(({id}: GetLoggedInUserAccountsResponseAccount) => id === accountId) as GetLoggedInUserAccountsResponseAccount
    const [role, setRole] = useState<AccountRole | null>(member?.account_role || null)
    const [email, setEmail] = useState(member?.user.email || '')
    const [isFresh, setIsFresh] = useState(true)
    const [isValid, setIsValid] = useState(false)
    const [isEmailError, setIsEmailError] = useState(false)
    const [updateMember, {isLoading: isUpdatingMember}] = useUpdateMemberMutation()
    const [sendInvitation, {isLoading: isSendingInvite}] = useSendInviteMutation()
    const isUpdating = !!member
    const buttonTitle = isUpdating ? role ? 'Update Member' : 'Remove Member' : 'Invite Member'
    const isAccountOwner = currentAccount && [AccountRole.ADMIN, AccountRole.OWNER].includes(currentAccount.role)

    useEffect(() => {
        if (isUpdating) {
            setIsValid(role !== member?.account_role)
        } else {
            setIsValid(!(isEmailError || !email || !role))
        }
    }, [role, isEmailError, isUpdating, email])

    useEffect(() => {
        if (!isFresh) {
            const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            const isValidEmail = emailRegEx.test(email)
            if (isEmailError === isValidEmail) {
                setIsEmailError(!isValidEmail)
            }
        }
    }, [email, isFresh])

    const handleRoleClick = (nextRole: AccountRole) => {
        setRole(role === nextRole ? null : nextRole)
    }

    const submitRemoveMember = async () => {
        if (!accountId || !member) {
            toast.error("Something went wrong. Please contact support")
            return
        }

        await toast.promise(
            updateMember({
                accountId: accountId,
                userId: member.user.id,
                member_state: AccountMemberState.REMOVED,
            }).unwrap(),
            {
                loading: "Updating",
                success: () => "User removed",
                error: (err: APIError) => {
                    return (
                        "Updating failed: " +
                        (err.data.length > 0
                            ? err.data[0].msg
                            : "An error occurred")
                    )
                },
            }
        )
        onClose()
    }

    const submitChangeRole = async () => {
        if (!accountId || !member) {
            toast.error("Something went wrong. Please contact support")
            return
        }

        await toast.promise(
            updateMember({
                accountId: accountId,
                userId: member.user.id,
                account_role: role as number,
            }).unwrap(),
            {
                loading: "Updating",
                success: () => "Role changed",
                error: (err: APIError) => {
                    return (
                        "Updating failed: " +
                        (err.data.length > 0
                            ? err.data[0].msg
                            : "An error occurred")
                    )
                },
            }
        )
        onClose()
    }

    const submitSendInvite = async () => {
        if (!accountId) {
            toast.error("Something went wrong. Please contact support")
            return
        }

        await toast.promise(
            sendInvitation({
                email,
                initial_role: role as AccountRole,
                account_id: accountId,
            }).unwrap(),
            {
                loading: "Sending Invite",
                success: () => "Invite Sent",
                error: (err: APIError) => {
                    return (
                        "Invite failed: " +
                        (err.data.length > 0
                            ? err.data[0].msg
                            : "An error occurred")
                    )
                },
            },
        )

        onClose()
    }

    const handleSubmit = () => {
        if (isUpdating) {
            if (!role) {
                void submitRemoveMember()
            } else {
                void submitChangeRole()
            }
        } else {
            void submitSendInvite()
        }
    }

    return (
        <>
            <div className="w-full flex flex-col gap-[20px] p-[40px]">
                <div className="flex flex-col gap-[10px]">
                    <div className="text-gray-800 text-xl font-bold">{isUpdating ? "Update member" : "Add members to Apokto"}</div>
                </div>
                <div className={`flex flex-col gap-[10px] ${isUpdating && 'pointer-events-none opacity-50'}`}>
                    <div className="font-semibold text-gray-800 text-left">
                        Enter User E-Mail
                    </div>
                    <EmailInput
                        value={email}
                        onChange={setEmail}
                        onBlur={() => setIsFresh(false)}
                        isInvalid={isEmailError}
                    />
                </div>
                <div className="flex flex-col gap-[10px]">
                    <div className="font-semibold text-gray-800 text-left">
                        Select Role
                    </div>
                    <div className="flex flex-col gap-[16px]">
                        {isAccountOwner && (
                            <RoleToggle
                                isActive={role === AccountRole.ADMIN}
                                onClick={() => handleRoleClick(AccountRole.ADMIN)}
                                title="Admin"
                                icon={PersonCheck}
                                description={
                                    <div className="text-slate-500 text-sm font-medium text-left">
                                        <div className="inline">This user has access to:&nbsp;</div>
                                        <div className="text-gray-800 text-sm font-medium inline">Billing, company creation, and user management</div>
                                    </div>
                                }
                            />
                        )}
                        <RoleToggle
                            isActive={role === AccountRole.USER}
                            onClick={() => handleRoleClick(AccountRole.USER)}
                            title="Member"
                            icon={PersonBadge}
                            description={
                                <div className="text-slate-500 text-sm font-medium text-left">This user has access to the scope an admin gives them access to.</div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="bg-slate-50 p-[20px] flex justify-end rounded-b-xl">
                <Button
                    type="submit" 
                    title={buttonTitle}
                    disabled={!isValid}
                    onClick={handleSubmit}
                    loading={isUpdatingMember || isSendingInvite}
                />
            </div>
        </>
    )
}
