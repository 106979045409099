import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CompanyTool, CompanyToolId } from "features/companies/types"
import { RootState } from "redux/store"

const tools = createSlice({
    name: "tools",
    initialState: {
        tools: [] as CompanyTool[],
    },
    reducers: {
        setTools: (
            state,
            action: PayloadAction<CompanyTool[]>,
        ) => {
            state.tools = action.payload.map((tool) => ({
                ...tool,
                is_enabled: tool.id === CompanyToolId.Alerts ? false : tool.is_enabled,
            }))
        },
    },
})

export const {setTools} = tools.actions
export const toolsState = (state: RootState) => state.tools
export default tools.reducer
