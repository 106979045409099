import { currentUser } from "features/authentication/authenticationSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ENV } from "config"
import { useLocation } from "react-router-dom";
import { isMobileBrowser }  from "utils/Utils";
import useScreenWidth from "hooks/useScreenWidth";

export default function Intercom() {
    const location = useLocation()
    const user = useSelector(currentUser)
    const screenWidth = useScreenWidth()
    const isMobile = isMobileBrowser()

    useEffect(() => {
        let script: null | HTMLScriptElement = null

        if (!window.Intercom && !isMobile) {
            script = document.createElement('script')
            script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${ENV.INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
            document.head.appendChild(script)
        }

        return () => {
            if (script) {
                document.head.removeChild(script)
            }
        }
    }, [])

    useEffect(() => {
        if (user) {
            if (window.Intercom) {
                window.Intercom('boot', {
                    api_base: 'https://api-iam.intercom.io',
                    app_id: ENV.INTERCOM_APP_ID,
                    userId: user?.sub,
                    email: user?.email,
                })
            }
          
            return () => {
                if (window.Intercom) {
                    window.Intercom('shutdown')
                }
            }
        }
    }, [user])

    useEffect(() => {
        if (window.Intercom) {
            window.Intercom('update')
        }
    }, [location])

    return null
}
