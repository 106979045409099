import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "redux/store"
import { DashboardDateRange, DashboardsState } from "./types"
import { currentYear, getRangeForYear } from "utils/DateUtils"

const dashboardsSlice = createSlice({
    name: "dashboards",
    initialState: {
        dateRange: {
            startDate: getRangeForYear(currentYear - 1).startDate.toISOString(),
            endDate: getRangeForYear(currentYear - 1).endDate.toISOString(),
            key: "last_twelve_months",
        },
    } as DashboardsState,
    reducers: {
        setDateRange(state, action: PayloadAction<DashboardDateRange>) {
            state.dateRange = action.payload
        },
    },
})

export const {setDateRange} = dashboardsSlice.actions
export default dashboardsSlice.reducer
export const dashboardsState = (state: RootState) => state.dashboards
