import { ReactNode } from "react"
import { Switch } from '@headlessui/react'

interface Props {
    isActive: boolean
    onClick: () => void
    title: string
    icon: string
    description: ReactNode
}

export default function RoleToggle({
    isActive,
    onClick,
    title,
    icon,
    description,
}: Props) {
    return (
        <div className="border-[1px] border-slate-200 rounded-md px-[20px] py-[15px] flex gap-[15px] align-top shadow-sm">
            <img className="relative top-[4px] h-[24px]" src={icon} />
            <div className="flex flex-col items-start">
                <div className="font-medium">{title}</div>
                {description}
            </div>
            <div className="self-center">
                <Switch
                    checked={isActive}
                    onChange={() => onClick()}
                    className={`${
                        isActive ? 'bg-blue-600' : 'bg-slate-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                    <span className="sr-only">Enable notifications</span>
                    <span
                        className={`${
                            isActive ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
            </div>
        </div>
    )
}