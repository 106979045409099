import ArchiveModal from "components/ModalProvider/ArchiveModal"
import DeleteModal from "components/ModalProvider/DeleteModal"
import FeedbackModal from "components/ModalProvider/FeedbackModal"
import Modal from "components/_shared/Modal/Modal"
import AddMemberModal from "components/AccountSettings/Members/Modals/AddMemberModal/AddMemberModal"
import AcknowledgeBillingCancelModal from "components/Home/AcknowledgeBillingCancelModal"
import ConfirmStripeRedirectModal from "components/Home/ConfirmStripeRedirectModal"
import {
    homeState,
    setAcknowledgeBillingCancelModal,
    setAddMemberModal,
    setArchiveCompanyModal,
    setConfirmStripeRedirectModal,
    setDeleteCompanyModal,
} from "features/home/homeSlice"
import { setDeleteConversationModal, setFeedbackModal, zoeState } from "features/zoe/zoeSlice"
import { useDispatch, useSelector } from "react-redux"

export default function ModalProvider() {
    const dispatch = useDispatch()
    const {
        feedbackModal,
        deleteConversationModal,
    } = useSelector(zoeState)
    const {
        addMemberModal,
        archiveCompanyModal,
        deleteCompanyModal,
        confirmStripeRedirectModal,
        acknowledgeBillingCancelModal,
    } = useSelector(homeState)

    return (
        <>
            {feedbackModal && (
                <Modal
                    onClose={() => dispatch(setFeedbackModal(null))}
                    containerClassName="sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)]"
                >
                    <FeedbackModal
                        {...feedbackModal}
                        onClose={() => dispatch(setFeedbackModal(null))}
                    />
                </Modal>
            )}
            {addMemberModal && (
                <Modal
                    onClose={() => dispatch(setAddMemberModal(null))}
                    containerClassName="sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)]"
                >
                    <AddMemberModal
                        {...addMemberModal}
                        onClose={() => dispatch(setAddMemberModal(null))}
                    />
                </Modal>
            )}
            {archiveCompanyModal && (
                <Modal
                    containerClassName="sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)]"
                    onClose={() => dispatch((setArchiveCompanyModal(null)))}
                >
                    <ArchiveModal
                        {...archiveCompanyModal}
                        onComplete={() => dispatch((setArchiveCompanyModal(null)))}
                        onClose={() => dispatch((setArchiveCompanyModal(null)))}
                    />
                </Modal>
            )}
            {deleteCompanyModal && (
                <Modal
                    containerClassName="sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)]"
                    onClose={() => dispatch((setDeleteCompanyModal(null)))}
                >
                    <DeleteModal
                        {...deleteCompanyModal}
                        onComplete={() => dispatch((setDeleteCompanyModal(null)))}
                        onClose={() => dispatch((setDeleteCompanyModal(null)))}
                    />
                </Modal>
            )}
            {confirmStripeRedirectModal && (
                <Modal
                    containerClassName="sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)]"
                    onClose={() => dispatch((setConfirmStripeRedirectModal(null)))}
                >
                    <ConfirmStripeRedirectModal
                        {...confirmStripeRedirectModal}
                        onClose={() => dispatch((setConfirmStripeRedirectModal(null)))}
                    />
                </Modal>
            )}
            {acknowledgeBillingCancelModal && (
                <Modal
                    containerClassName="sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)]"
                    onClose={() => dispatch((setAcknowledgeBillingCancelModal(null)))}
                >
                    <AcknowledgeBillingCancelModal
                        {...acknowledgeBillingCancelModal}
                        onClose={() => dispatch((setAcknowledgeBillingCancelModal(null)))}
                    />
                </Modal>
            )}
        </>
    )
}
