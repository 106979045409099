import React, { ReactNode } from "react"

type Props = {
    title: string
    className?: string
    onClick?: (e: any) => any
    type?: "submit" | "reset" | "button" | undefined
    iconLeft?: ReactNode
    iconRight?: ReactNode
    disabled?: boolean
    loading?: boolean
    kind?: "primary" | "outline" | "danger"
    style?: React.CSSProperties
    loaderClassName?: string
}

export default function Button({
    title,
    className = "",
    onClick = () => {},
    type = "button",
    kind = "primary",
    iconLeft = null,
    iconRight = null,
    disabled = false,
    loading = false,
    style = {},
    loaderClassName = "",
    ...rest
}: Props) {
    const isDisabled = disabled || loading
    let customStyle = ""

    if (kind === "primary")
        customStyle = `py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-secondary-blue text-white hover:bg-blue-700 transition-all text-sm focus:outline-none`
    if (kind === "outline")
        customStyle = `py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-blue-200/[0.2] border-2 border-blue-700 font-semibold font-semibold text-blue-700 hover:text-white hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm`
    if (kind === "danger")
        customStyle = `py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border bg-[#EE34340D] border-[#E71010] text-[#E71010] hover:bg-[#E71010] hover:text-white outline-none text-sm`
    // TODO: Check if "outline" could be refactored based only of className conditional rendering

    return (
        <button
            {...rest}
            disabled={isDisabled}
            type={type}
            className={`cursor-pointer ${customStyle} ${
                isDisabled ? "!bg-gray-300 !hover:bg-slate-200" : ""
            } ${className}`}
            onClick={(e) => {
                if (!isDisabled) onClick(e)
            }}
            style={style}
        >
            {loading && (
                <span
                    className={
                        "animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full " +
                        loaderClassName
                    }
                    role="status"
                    aria-label="loading"
                ></span>
            )}
            {iconLeft && iconLeft}
            {title}
            {iconRight && iconRight}
        </button>
    )
}
