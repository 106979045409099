import { useSubmitFeedbackMutation } from "features/zoe/zoeAPI"
import toast from "react-hot-toast"
import { APIError } from "types/errors"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import Checkbox from "components/_shared/Checkbox/Checkbox"
import { MessageFeedbackBody } from "features/zoe/types"
import Button from "components/common/Button"

interface Props {
    isPositive: boolean
    messageId: number
    conversationId: string
    onClose: () => void
}

export default function FeedbackModal({isPositive, messageId, conversationId, onClose}: Props) {
    const [submitFeedback, {isLoading: isSubmittingFeedback}] = useSubmitFeedbackMutation()
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
    const [text, setText] = useState('')
    const [isHarmful, setIsHarmful] = useState(false)
    const [isUnhelpful, setIsUnhelpful] = useState(false)
    const [isUntrue, setIsUntrue] = useState(false)
    const placeholderText = isPositive ? 'What did you like about this response?' : 'What was the issue with this response? How could it be improved?'

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus()
        }
    }, [textAreaRef.current])

    const handleSubmit = () => {
        const form: MessageFeedbackBody = {
            type: isPositive ? 'positive' : 'negative',
            text,
            harmful: isHarmful,
            unhelpful: isUnhelpful,
            untrue: isUntrue,
        }

        submitFeedback({conversationId, messageId, ...form}).unwrap()
            .then(() => {
                toast.success('Feedback submitted')
            })
            .catch(({data}: APIError) => {
                toast.error('Submission Failed: ' + (data.length > 0 ? data[0].msg : 'An error occurred'))
            })
            .finally(() => {
                onClose()
            })
    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value)
    }

    return (
        <div className="w-full flex flex-col p-5 gap-5">
            <textarea
                ref={textAreaRef}
                className="text-sm tracking-[0.08px] text-slate-500 p-2"
                value={text}
                onChange={handleChange}
                placeholder={placeholderText}
            />
            {!isPositive && (
                <div className="flex flex-col gap-1">
                    <Checkbox label="This is harmful / unsafe" value={isHarmful} onChange={setIsHarmful} />
                    <Checkbox label="This isn't true" value={isUntrue} onChange={setIsUntrue} />
                    <Checkbox label="This isn't helpful" value={isUnhelpful} onChange={setIsUnhelpful} />
                </div>
            )}
            <div className="w-full flex justify-end">
                <Button
                    onClick={handleSubmit}
                    text="Submit Feedback"
                    loading={isSubmittingFeedback}
                    disabled={!text.length}
                    small
                />
            </div>
        </div>
            
    )
}